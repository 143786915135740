import React, { useEffect } from "react";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "react-scroll-to-top";

export function ScrollTop() {
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <ScrollToTop
      data-aos="fade-up"
      smooth
      style={{
        border: "1px solid #fff",
        borderRadius: "50%",
        padding: "6px",
        background: "#fff",
        boxShadow: "none",
      }}
      color="black"
    />
  );
}
