import React, { useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Linked from "../../Assets/Linked.svg";
import { firestore } from "../../../Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "10% auto",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "25%",
    },
  },
  leftBox: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  ModelImage: {
    width: "78%",
  },
  Header: {
    font: "normal normal bold 1.8vw Montserrat",
    color: "#168ACB",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  Headering: {
    font: "normal normal bold 1.2vw Montserrat",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  subHeader: {
    font: "normal normal normal 1.5vw Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      marginTop: "2%",
    },
  },
  rightBox: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "5%",
    },
  },
  SubPara: {
    font: "normal normal normal 1.5vw Montserrat",
    lineHeight: "1.4",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  companyImg: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const AboutAdvisor = () => {
  const classes = useStyles();
  const userId = useParams().id;
  const [userData, setUserData] = useState([]);

  const handleFetchUserData = async () => {
    const advisorRef = firestore.collection("AdvisorteamMemberQuain").doc(userId);
    const adviorSnapshot = await advisorRef.get();

    if (adviorSnapshot.exists) {
      const advisorMemberData = adviorSnapshot.data();
     
      setUserData([advisorMemberData]);
  };
}

  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
    handleFetchUserData();
  }, [userId]);

  return (
    <>
      {userData ? (
        userData.map((item, index) => {
          const {
            aboutUs,
            exprince,
            link,
            logoImage,
            memberName,
            profileImage,
            readMore,
          } = item;
          return (
            <Box className={classes.root}>
              <Box className={classes.leftBox} key={index}>
                <img
                  className={classes.ModelImage}
                  src={profileImage}
                  alt={memberName}
                />
                <br /> <br />
                <h1 className={classes.Header}>{memberName}</h1>
                <br /> <br />
                {exprince ? (
                  <h1 className={classes.Headering}>{exprince}</h1>
                ) : (
                  ""
                )}
              </Box>
              <Box className={classes.rightBox}>
                <p className={classes.SubPara}>{aboutUs}</p>
                <br />
                <p className={classes.SubPara}>{readMore}</p>
                <img
                  className={classes.companyImg}
                  src={logoImage}
                  alt="Company"
                />

                {/* <Box>
                  <a href={link} target="_blank" rel="noreferrer">
                    <img src={Linked} alt="linked" />
                  </a>
                </Box> */}
              </Box>
            </Box>
          );
        })
      ) : (
        <h1>Loading</h1>
      )}
    </>
  );
};


