import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import "aos/dist/aos.css";
import aos from "aos";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "5%",
    },
  },
  position: {
    position: "relative",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10%",
      width: "100%",
    },
  },
  opa: {
    marginTop:"-3%",
    textAlign:"left",
    color: "#168ACB",
    opacity: "0.1",
    font: "normal normal bold 10rem Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6rem",
    },
  },
  Head: {
    color: "#168ACB",
    marginBottom: "5%",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    position: "absolute",
    top: "15%",
    left: "8%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      top: "5%",
    },
  },
  marLeft: {
    marginTop:"10%",
    marginLeft: "8%",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0%",
    },
  },
  praCenter: {
    marginBottom: "5%",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "1.4",
    [theme.breakpoints.down("sm")]: {
      fontSize: "5vw",
      marginTop: "0%",
    },
  },
  ul: {
    margin: "-20% 0% 0% 5%",
    textAlign: "left",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  li: {
    margin: "2% 0%",
    [theme.breakpoints.down("sm")]: {
      margin: "5% 0%",
    },
  },
  Image: {
    width: "100%",
  },
}));

export const LeftFlex1 = ({ data }) => {
  const classes = useStyles();
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.position}>
        <h1 className={classes.opa}>04</h1>
        <h1 className={classes.Head}>{data ? data[17].state : "loading"}</h1>

        <Box className={classes.marLeft}>
          <ul className={classes.ul}>
            <li className={classes.li} data-aos="fade-up">
              Management Team
            </li>
            <li className={classes.li} data-aos="fade-up">
              Market Size and Growth
            </li>
            <li className={classes.li} data-aos="fade-up">
              Company Momentum
            </li>
            <li className={classes.li} data-aos="fade-up">
              Investor Strength
            </li>
          </ul>
        </Box>
      </Box>

      <Box className={classes.position}>
        <img
          className={classes.Image}
          src={"./Images/Plateform/platform4.png"}
          alt="Svg"
          data-aos="fade-up"
        />
      </Box>
    </Box>
  );
};
