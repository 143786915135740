import React, { useEffect, useState } from "react";
import { Box, makeStyles, Grid } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
//Router
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "4% auto",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  itemGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
    },
  },
  subHeader: {
    font: "normal normal normal 1.6vw Montserrat",
    cursor: "pointer",
    textAlign: "center",
    marginTop: "5%",

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      marginTop: "2%",
    },
  },
  imgWrapper: {
    overflow: "hidden",
    height: "400px",
    width: "80%",
    borderRadius: "10px",
    transition: "all 500ms ease",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
    "&:hover": {
      "& img": {
        transform: "scale(1.1)",
      },
    },
  },
  profileImg: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
    objectFit: "cover",
    transition: "all 500ms ease",
    borderRadius: "30px",
  },
  link: {
    marginTop: "0.5rem",
    transition: "all .3s ease",
    color: "#168ACB",
    cursor: "pointer",
    "&:hover": { opacity: "0.8" },
  },
  role: {
    fontSize: "1rem",
    fontWeight: "400",
    marginTop: "0.5rem",
    color: "black",
    textAlign: "center",
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    textAlign: "center",
    margin: "-2% 10% 5% 10%",
  },
  mainHeader_para: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
    textAlign: "center",
    marginBottom: "120px",
  },
  mainHeader_container: {
    padding: "0% 10%",
    textAlign: "center",
  },
  "@media (max-width: 1367px)": {
    itemGrid: {
      marginBottom: "20px",
    },
  },
}));

export const AdvisorCard = ({ advisorData, data }) => {
  const classes = useStyles();
  const history = useNavigate();

  return (
    <Box className={classes.root} data-aos="fade-up">
      <Box className={classes.mainHeader_container}>
        <p className={classes.mainHeader}>
          <span className={`position-relative`}>
            {" "}
            {data ? advisorData[0].state : "Loading"}
          </span>
          <div className="highlighter"></div>
        </p>
        <p className={classes.mainHeader_para}>
          {data ? advisorData[1].state : "Loading"}
        </p>
      </Box>
      <Grid container xs={12}>
        {data ? (
          data.map((item, index) => {
            const { profileImage, memberName, id, exprince } = item;
            return (
              <Grid
                data-aos="fade-up"
                key={index}
                item
                xs={12}
                sm={4}
                className={classes.itemGrid}
              >
                <Box className={classes.imgWrapper}>
                  <img
                    className={classes.profileImg}
                    src={profileImage}
                    alt="model"
                    onClick={() => history(`/aboutadvisor/${id}`)}
                  />
                </Box>
                <br />
                <h1 className={classes.subHeader}>{memberName}</h1>
                <h1 className={classes.role}>{exprince}</h1>
                <h3
                  className={classes.link}
                  onClick={() => history(`/aboutadvisor/${id}`)}
                >
                  Read more
                </h3>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} sm={4} className={classes.itemGrid}>
            <h1>Loading</h1>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
