import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  Button,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import { firestore } from "../../../../../Firebase";
import { toast } from "react-toastify";
const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "30px",
  },
  loadingText: {
    fontSize: "2rem",
  },
  deleteCell: {
    "&:hover": {
      color: "red",
    },
  },
  modalWrapper: {},
  modalHeading: {
    fontSize: "2rem",
    padding: "30px 10px 10px 20px",
    fontFamily: "Montserrat-semibold",
    marginBottom: "75px",
  },
  modalBtnWrapper: {
    padding: "20px 15px",
  },
}));

export default function ManageTeamMembers() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const classes = useStyles();

  const [isModalOpen, setModalOpen] = useState(false);
  const [target, setTarget] = useState("");
  const handleModalOpen = (id) => {
    setModalOpen(true);
    setTarget(id);
  };

  const handleClose = () => {
    setModalOpen(false);
    setTarget("");
  };
  const handleProceed = () => {
    handleClose();
    handleDelete();
  };

  useEffect(() => {
    firestore
      .collection("AdvisorteamMemberQuain")
      .orderBy("memberName", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), id: doc.id });
        });
        setData(item);
        setLoading(false);
      });
  }, []);
  const handleDelete = (id) => {
    toast.info("Processing...");
    firestore
      .collection("AdvisorteamMemberQuain")
      .doc(target)
      .delete()
      .then(() => {
        toast.success("Item deleted successfully");
      })
      .catch(() => {
        toast.error("Failed to delete item");
      });
  };

  if (isLoading) {
    return <center className={classes.loadingText}>Loading...</center>;
  } else {
    return (
      <>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.modalWrapper}
        >
          <span className={classes.modalHeading}>Do You Want to proceed?</span>

          <DialogActions className={classes.modalBtnWrapper}>
            <Button variant="outlined" onClick={handleClose}>
              Disagree
            </Button>
            <Button onClick={handleProceed} variant="contained">
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr No</TableCell>
                <TableCell>Context</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((elem, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{elem.memberName}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      className={classes.deleteCell}
                      onClick={() => handleModalOpen(elem.id)}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
