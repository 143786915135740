import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import "aos/dist/aos.css";
import aos from "aos";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "5rem",
    },
  },
  position: {
    width: "48%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20%",
      width: "100%",
    },
  },
  opa: {
    marginTop:"3%",
    textAlign:"left",
    color: "#168ACB",
    opacity: "0.1",
    font: "normal normal bold 10rem Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6rem",
    },
  },
  Head: {
    color: "#168ACB",
    textAlign:"left",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    position: "absolute",
    top: "15%",
    left: "8%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "7vw",
    },
  },
  marLeft: {
    marginLeft: "8%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15%",
    },
  },
  praCenter: {
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "700",
    textAlign:"left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      margin: "25% 0%",
    },
  },
  ul: {
    margin: "6% 0% 0% 5%",
    textAlign:"left",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "400",
    textAign:"left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  li: {
    margin: "2% 0%",
    [theme.breakpoints.down("sm")]: {
      margin: "5% 0%",
    },
  },
  subLine: {
    font: "normal normal 300 24px Montserrat",
  },
  Image: {
    width: "100%",
  },
}));

export const RightFlex1 = ({ data }) => {
  const classes = useStyles();
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.position}>
        <img
          className={classes.Image}
          src={"./Images/Plateform/platform3.png"}
          alt="Svg"
          data-aos="fade-up"
        />
      </Box>

      <Box className={classes.position}>
        <h1 className={classes.opa}>03</h1>
        <h1 className={classes.Head}>{data ? data[32].state : "loading"}</h1>
        <Box className={classes.marLeft}>
          <p className={classes.praCenter} data-aos="fade-up">
            {data ? data[15].state : "loading"}
          </p>
          <ul className={classes.ul}>
            <li className={classes.li} data-aos="fade-up">
              {data ? data[41].state : "loading"}
            </li>
            <li className={classes.li} data-aos="fade-up">
              {data ? data[24].state : "loading"}
            </li>
            <li className={classes.li} data-aos="fade-up">
              {data ? data[5].state : "loading"}
            </li>
            <li className={classes.li} data-aos="fade-up">
              {data ? data[9].state : "loading"}
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};
