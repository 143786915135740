import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, makeStyles } from "@material-ui/core";
import { HomeDashboard } from "./HomeDashboard";
import { AboutDashboard } from "./AboutDashboard";
import { PortFolioDashboard } from "./PortFolioDashboard";
import { SocialResponsibilityDashboard } from "./SocialResponsibility";
import { PlatformDashboard } from "./PlatformDashboard";
import { NewsAndBlog } from "./News&Blog";
import { toast } from "react-toastify";
import { auth } from "../../../Firebase";
export * from "./login";

const active = {
  backgroundColor: "#fff",
  border: "1px solid black",
  color: "#000",
};

const unActive = {
  backgroundColor: "#168ACB",
  border: "1px solid #168ACB",
  color: "#fff",
};

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    height: "400px",
    width: "100%",
    display: "grid",
    placeContent: "center",
  },
  root: {
    width: "100%",
    margin: "10% 0%",
    display: "flex",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "20%",
    padding: "2%",
    border: "2px solid black",
  },
  leftInnerBox: {
    border: "1px solid black",
    borderRadius: "10px",
    font: "normal normal normal 10px Montserrat",
    margin: "5% 0%",
    padding: "2%",
    "&:hover": {
      backgroundColor: "#168ACB",
      border: "1px solid #168ACB",
      color: "#fff",
    },
  },
  leftHead: {
    cursor: "pointer",
  },
  rightBox: {
    width: "80%",
    border: "2px solid black",
  },
}));

export const Admin = () => {
  const classes = useStyles();
  const history = useNavigate();
  const [show, setShow] = useState("home");
  const [isverified, setVerified] = useState(false);
  const logout = () => {
    const ans = window.prompt("Do  you want to proceed. Type 'YES' to proceed");
    if (ans === "YES") {
      sessionStorage.clear();
      history("/");
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      setVerified(true);
    } else {
      history("/login");
    }
  });

  if (isverified) {
    return (
      <Box className={classes.root}>
        <Box className={classes.leftBox}>
          <Box
            className={classes.leftInnerBox}
            style={show === "home" ? unActive : active}
          >
            <h1 className={classes.leftHead} onClick={() => setShow("home")}>
              Home
            </h1>
          </Box>
          <Box
            className={classes.leftInnerBox}
            style={show === "About" ? unActive : active}
          >
            <h1 className={classes.leftHead} onClick={() => setShow("About")}>
              About Quain
            </h1>
          </Box>
          <Box
            className={classes.leftInnerBox}
            style={show === "Platform" ? unActive : active}
          >
            <h1
              className={classes.leftHead}
              onClick={() => setShow("Platform")}
            >
              Platform
            </h1>
          </Box>
          <Box
            className={classes.leftInnerBox}
            style={show === "SocialResponsibility" ? unActive : active}
          >
            <h1
              className={classes.leftHead}
              onClick={() => setShow("SocialResponsibility")}
            >
              Social Responsibility
            </h1>
          </Box>
          <Box
            className={classes.leftInnerBox}
            style={show === "Portfolio" ? unActive : active}
          >
            <h1
              className={classes.leftHead}
              onClick={() => setShow("Portfolio")}
            >
              Portfolio
            </h1>
          </Box>

          <Box className={classes.leftInnerBox}>
            <h1 className={classes.leftHead} onClick={logout}>
              Log Out
            </h1>
          </Box>
        </Box>
        <Box className={classes.rightBox}>
          {show === "home" ? <HomeDashboard /> : ""}
          {show === "About" ? <AboutDashboard /> : ""}
          {show === "Platform" ? <PlatformDashboard /> : ""}
          {show === "Portfolio" ? <PortFolioDashboard /> : ""}
          {show === "SocialResponsibility" ? (
            <SocialResponsibilityDashboard />
          ) : (
            ""
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className={classes.loadingWrapper}>
        <h1 className={classes.leftHead}>Loading...</h1>
      </Box>
    );
  }
};
