import {
  Home,
  About,
  AboutPlatform,
  Contactus,
  SocialResponsibility,
  Portfolio,
  Admin,
  Login,
  AboutTeam,
  AboutAdvisor,
  Error404,
} from "../Components/Pages";
export const PageRoutes = [
  {
    path: "/",
    title: "/home",
    component: () => <Home />,
  },
  {
    path: "/about",
    title: "/about",
    component: () => <About />,
  },
  {
    path: "/about-platform",
    title: "/about-platform",
    component: () => <AboutPlatform />,
  },
  {
    path: "/portfolio",
    title: "/portfolio",
    component: () => <Portfolio />,
  },
  {
    path: "/social-responsibility",
    title: "/social-responsibility",
    component: () => <SocialResponsibility />,
  },
  {
    path: "/contact-us",
    title: "/contact-us",
    component: () => <Contactus />,
  },
  {
    path: "/admin",
    title: "/admin",
    component: () => <Admin />,
  },
  {
    path: "/login",
    title: "/login",
    component: () => <Login />,
  },
  {
    path: "/aboutTeam/:id",
    title: "/aboutTeam/:id",
    component: () => <AboutTeam />,
  },
  {
    path: "/aboutadvisor/:id",
    title: "/aboutadvisor/:id",
    component: () => <AboutAdvisor />,
  },
  {
    path: "*",
    title: "Error 404 Not Found",
    component: () => <Error404 />,
  },
];
