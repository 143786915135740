import React, { Fragment, useEffect } from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  overlayContainer: {
    position: "relative",
    width: "100%",
    height: "500px",
    overflow: "hidden",
    marginTop: "-4px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-38px",
      lineHeight: "1.5",
      width: "100%",
      height: "100%",
    },
  },
  backgroundImage: {
    width: "100%",
    objectFit: "fill",
    backgroundPositionY: "center",
    opacity: "1",
    position: "fixed",
    top: "-30%",
    [theme.breakpoints.down("sm")]: {
      top: "-3%",
      position: "relative",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(38, 83, 131, 0.7)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#fff",
    textAlign: "justify",
  },
  overlay_text: {
    color: "#FFFF",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "900",
    marginTop: "80px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "10px",
      marginTop: "50px",
    },
  },
  Collaborator_banner: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#ffff",
    opacity: "1",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "80px",
      marginTop: "10px",
    },
  },
  Collaborator_banner_logo: {
    width: "60%",
    height: "auto",
    padding: "3% 0%",
    marginLeft: "13%",
  }
}));

export const Collaboration = ({ data, logo }) => {
  console.log(data);
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Fragment>
      <Box className={classes.overlayContainer} data-aos="fade-up">
        <img
          className={classes.backgroundImage}
          src="/Images/Bg/BG9.png"
          alt="Background"
        />
        <Box className={classes.overlay}>
          <p className={classes.overlay_text}>
            {data ? data[16].state : "loading"}
          </p>
          <Box className={classes.Collaborator_banner} data-aos="fade-up">
            <Grid container spacing={2} className={classes.collaboratorBanner}>
              {logo ? logo.map((list, index) => {
                if (index > 3) {
                  return ""
                }
                return (<Grid key={index} item xs={3} sm={3}>
                  <img className={classes.Collaborator_banner_logo} src={list.logoImage} alt={"logo_" + index} />
                </Grid>)
              }) : ""}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
