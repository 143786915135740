import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
// Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  backImg: {
    width: "100%",
    height: "100%",
    backgroundPosition: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20px 0",
      fontSize: "1rem",
      lineHeight: "1.5",
      height: "300px",
    },
  },
  overlay: {
    position: "absolute",
    top: "54%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 70px)",
    height: "calc(100% - 150px)",
    backgroundColor: "#FFFDFD",
    borderRadius: "20px",
    opacity: 0.9,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      top: "58%",
      flexDirection: "column",
    },
  },
  PositionRelative: {
    position: "relative",
  },
  leftContent: {
    flex: 1,
    padding: "20px",
  },
  rightContent: {
    width:"50%",
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "10px",
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  leftContentText:{
    color:"#168ACB",
    fontSize:"90px",
    fontFamily:"Nunito-Regular",
    fontWeight:"600",
    marginTop:"20px",
    marginLeft:"1%",
    width:"100%",
    [theme.breakpoints.down("sm")]: {
      fontSize:"18px",
      marginTop:"20px",
    },
    [theme.breakpoints.down("xl")]: {
      // width:"79%"
    }
  },
  leftContentText_header:{
    fontSize:"36px",
    fontFamily:"Nunito-Regular",
    fontWeight:"700",
    marginTop:"50px",
    marginLeft:"1%",
    [theme.breakpoints.down("sm")]: {
      fontSize:"18px",
      marginTop:"20px",
    }
  },
  rightContentImg:{
    width:"100%",
    height:"auto",
    marginTop:"18%",
    paddingRight:"8%",
    [theme.breakpoints.down("sm")]: {
      width:"60%",
      height:"100%",
      marginTop:"-10px"
    }
  }
}));

export const Intro = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box className={classes.PositionRelative} data-aos="fade-up">
      <img src={"/Images/Bg/BG15.png"} className={classes.backImg} />
      <div className={classes.overlay}>
        <div className={classes.leftContent} data-aos="fade-up">
          <p className={classes.leftContentText_header}> {data ? data[13].state : "loading"}</p>
          <p className={classes.leftContentText}> {data ? data[16].state : "loading"}</p>
        </div>
        <div className={classes.rightContent} data-aos="fade-right">
          <img className={classes.rightContentImg}
            src="./Images/Plateform/platform_header.png"
            alt=""
          />
        </div>
      </div>
    </Box>
  );
};
