import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  inputField: {
    padding: "4%",
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #000",
  },
  button: {
    width: "12rem",
    padding: "1%",
    borderRadius: "10px",
    border: "none",
    backgroundColor: "#168ACB",
    color: "#fff",
    font: "normal normal bold 1rem Montserrat",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "5%",
      width: "5rem",
    },
  },
}));

export const UpdateHomePop = ({
  open,
  handleClose,
  HomeDetails,
  handleUpdate,
}) => {
  const classes = useStyles();

  const [state, setState] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state) {
      toast.error("Please fill the input field");
    } else {
      handleUpdate(state);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth="100vw">
      <DialogTitle style={{ textAlign: "center" }}>
        Update Home Page Content
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <textarea
            className={classes.inputField}
            placeholder={HomeDetails.state}
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <br />
          <br />
          <button className={classes.button} type="submit">
            Update
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
