import React, { useState } from "react";
//Material Ui
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  Dialog,
  DialogActions,
  Button,
} from "@material-ui/core";
import { UpdateTilesPopup } from "./UpdateTilesPopup";

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "30px",
  },
  loadingText: {
    fontSize: "2rem",
  },
  deleteCell: {
    "&:hover": {
      color: "red",
    },
  },
  modalWrapper: {},
  modalHeading: {
    fontSize: "2rem",
    padding: "30px 10px 10px 20px",
    fontFamily: "Montserrat-semibold",
    marginBottom: "75px",
  },
  modalBtnWrapper: {
    padding: "20px 15px",
  },
}));

export const PortfolioTable = ({
  deleteTile,
  tilesData,
  open,
  handleClose,
  handleClickOpen,
  handleUpdateTiles,
  tileDetails,
}) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(10);
  const [isModalActive, setModalState] = useState(false);
  const [target, setTarget] = useState("");
  const openWarningModal = (id) => {

    setModalState(true);
    setTarget(id);
  };
  const closeWarningModal = () => {
    setModalState(false);
  };
  const loadMoreItems = () => {
    setVisible((previousValue) => previousValue + 10);
  };
  const handleProceed = () => {
    closeWarningModal();
    dummyDelete();
  };
  const dummyDelete = () => {
    deleteTile(target);
  };

  return (
    <>
      <Dialog
        maxW
        idth="sm"
        fullWidth
        open={isModalActive}
        onClose={closeWarningModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modalWrapper}
      >
        <span className={classes.modalHeading}>Do You Want to proceed?</span>

        <DialogActions className={classes.modalBtnWrapper}>
          <Button variant="outlined" onClick={closeWarningModal}>
            Disagree
          </Button>
          <Button onClick={handleProceed} variant="contained">
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr No</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>sector</TableCell>
                <TableCell>Stage</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Logo</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tilesData
                ? tilesData.slice(0, visible).map((item, index) => {
                  const {
                    companyName,
                    documentID,
                    logo,
                    sector,
                    stage,
                    link,
                  } = item;
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{companyName}</TableCell>
                      <TableCell>{sector}</TableCell>
                      <TableCell>{stage}</TableCell>
                      <TableCell>{link}</TableCell>
                      <TableCell>
                        <img src={logo} alt={companyName} />
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickOpen(documentID)}
                      >
                        Edit
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() => openWarningModal(documentID)}
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  );
                })
                : "Loading Please Wait"}
              <TableRow>
                <TableCell colSpan={6}></TableCell>
                <TableCell colSpan={2}>
                  <h4
                    style={{
                      cursor: "pointer",
                      font: "normal normal bold 1.1vw Montserrat",
                    }}
                    onClick={loadMoreItems}
                  >
                    Load More
                  </h4>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <UpdateTilesPopup
          open={open}
          handleClose={handleClose}
          handleUpdateTiles={handleUpdateTiles}
          tileDetails={tileDetails}
        />
      </div>
    </>
  );
};
