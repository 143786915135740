import React from "react";
import { Footer, Navbar } from "./Components/Molecule";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageRoutes } from "./Routes";
import "aos/dist/aos.css";
//Toastify
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@material-ui/core";
const theme = createTheme({
  palette: {
    brandColor: "red",
  },
});
function App() {
  return (
    <div>
      <Navbar />
      <ToastContainer />
      <Routes>
        {PageRoutes.map((item, index) => (
          <Route key={index} path={item.path} element={<item.component />} />
        ))}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
