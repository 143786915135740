import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  overlay: {
    width: "calc(100% - 38px)",
    height: "calc(100% - 150px)",
    backgroundColor: "#FFFDFD",
    borderRadius: "20px",
    opacity: 0.9,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      top: "58%",
      flexDirection: "column",
    },
  },
  leftContent: {
    width: "60%",
    flex: 1,
    marginTop: "30px",
  },
  rightContent: {
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "10px",
    textAlign: "center",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  leftContentText: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
    textAlign: "left",
    marginTop: "50px",
    marginLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  leftContentText_header: {
    fontSize: "36px",
    fontFamily: "Nunito-Regular",
    fontWeight: "700",
    marginTop: "50px",
    marginLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  rightContentImg: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "100%",
      marginTop: "-10px",
    },
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    margin: "50px 10px",
  },
}));

export const AboutSection = ({ data }) => {
  const classes = useStyles();
  let splitPara = "";
  let firstPart = "";
  let secondPart = "";
  if (data != undefined && data[2] != undefined) {
    splitPara = data[2].state.split("IPOs.");
    firstPart = splitPara[0] + "IPOs.";
    secondPart = splitPara.slice(1).join("IPOs");
  }

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box style={{ textAlign: "center", width: "100%" }}>
      <p className={classes.mainHeader}>
        <span data-aos="fade-up" className={`position-relative`}>
          {data ? data[38].state : "loading"}
        </span>
        <div className="highlighter"></div>
      </p>
      <div className={classes.overlay}>
        <div className={classes.leftContent} data-aos="fade-up">
          <div className={classes.leftContentText}>
            <p style={{ marginBottom: "30px" }}>
              {data ? data[36].state : "loading"}
            </p>
            <p style={{ marginBottom: "30px", color: "#168ACB" }}>
              {firstPart}
            </p>
            <p style={{ marginBottom: "30px" }}>{secondPart}</p>
          </div>
        </div>
        <div className={classes.rightContent} data-aos="fade-right">
          <img
            className={classes.rightContentImg}
            src="./Images/Plateform/platform.jpg"
            alt=""
          />
        </div>
      </div>
    </Box>
  );
};
