import React, { useEffect } from "react";
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "-80px",
    },
  },
  backgroundImage: {
    width: "100%",
    objectFit: "fill",
    backgroundPositionY: "center",
    opacity: "0.3",
    position: "fixed",
  },
  header_text: {
    fontSize: "32px",
    fontFamily: "Roboto-Regular",
    fontWeight: "500",
    textAlign: "center",
    marginTop: "50px",
    color: "#171616",
  },
  content_text: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "500",
    textAlign: "center",
    color: "#171616",
    marginTop: "30px",
    padding: "0px 10px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
    },
  },
  Image: {
    width: "100%",
    height: "100%",
    marginTop: "-302px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50px",
      marginLeft: "0px",
    },
  },
}));

export const TeamQuain = ({ data }) => {

  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Box className={classes.container} data-aos="fade-up">
      <img
        className={classes.backgroundImage}
        src="/Images/Bg/BG10.png"
        alt="Background"
      />
      <p className={classes.header_text} data-aos="fade-up">
        {data ? data[3].state : "loading"}
      </p>
      <p className={classes.content_text} data-aos="fade-up">
        {data ? data[7].state : "loading"}
      </p>
      <div data-aos="fade-up">
        <img
          className={classes.Image}
          src="/Images/About/leverage.png"
          alt="Background"
        />
      </div>
    </Box>
  );
};
