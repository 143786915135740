import React, { Fragment, useEffect } from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  para: {
    width: "65%",
    marginTop: "2%",
    marginBottom: "20% ",
    font: "normal normal normal 1.5vw Montserrat",
    lineHeight: "1.4",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10% 0%",
      marginBottom: "20% ",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  },
  backImg: {
    width: "100%",
    height: "100%",
    backgroundPosition: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20% 0%",
      marginBottom: "8% ",
      fontSize: "1rem",
      lineHeight: "1.5",
      height: "300px",
    },
  },
  PositionRelative: {
    position: "relative",
  },
  textBox: {
    position: "absolute",
    top: "20%",
    left: "45%",
    width: "50%",
    background: "transparent",
    backgroundColor: "#FFFDFD",
    opacity: "0.8",
    padding: "30px",
    borderRadius: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      top: "25%",
      left: "20%",
      width: "60%",
      padding: "10px",
    },
  },
  para: {
    fontSize: "55px",
    fontWeight: "700",
    fontFamily: "Nunito-Regular",
    left: "-45px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1rem",
      lineHeight: "1.5",
      left: "-10px",
      position: "relative",
    },
  },
  sub_para: {
    margin: "0",
    textAlign: "center",
    left: "-40px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1rem",
      lineHeight: "1.5",
      display: "flex",
      left: "5px",
      position: "relative",
    },
  },
  sub_para1: {
    fontSize: "55px",
    fontWeight: "700",
    color: "#168ACB",
    fontFamily: "Nunito-Regular",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  },
  sub_para2: {
    fontSize: "55px",
    fontWeight: "700",
    color: "#05992F",
    fontFamily: "Nunito-Regular",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1rem",
      lineHeight: "1.5",
      left: "-20px",
      position: "relative",
    },
  },
  trade_mark_text: {
    fontSize: "24px",
    fontWeight: "400",
    color: "#171616",
    fontFamily: "Nunito-Regular",
    left: "-17%",
    position: "relative",
    margin: "40px 10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "12px",
      lineHeight: "1.5",
      left: "0%",
      margin: "5px 0px",
    },
  },
  learn_more_btn: {
    borderRadius: "6px",
    padding: "10px",
    border: "1px solid #119EED",
    width: "132px",
    background: "#ffff",
    fontSize: "16px",
    fontFamily: "Nunito-Regular",
    left: "-33%",
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "90px",
      lineHeight: "1.5",
      left: "0%",
      padding: "5px",
      margin: "5px 0px",
    },
  },
  signUp_btn: {
    borderRadius: "6px",
    padding: "10px",
    color: "#FFFDFD",
    width: "132px",
    background: "#05992F",
    fontSize: "16px",
    fontFamily: "Nunito-Regular",
    left: "-20%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "1.5",
      left: "0%",
      width: "90px",
      padding: "5px",
      margin: "5px 10px",
    },
  },
  cardContainer: {
    position: "absolute",
    margin: "0 auto",
    textAlign: "center",
    top: "72%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "60%",
      left: "-2px"
    },
  },
  card: {
    backgroundColor: "#FFF",
    textAlign: "center",
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
    padding: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "1.5",
      width: "100%",
      height: "auto",
      left: "0%",
    },
  },
  card_count: {
    fontFamily: "Inter-Regular",
    fontSize: "56px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "1.5",
      width: "100%",
    },
  },
  card_sub_text: {
    fontFamily: "Nunito-Regular",
    fontSize: "24px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "1.5",
      width: "100%",
    },
  },
  card_icon: {
    width: "40px",
    height: "40px",
    marginBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "20px",
      height: "20px",
    },
  },
}));

export const Intro = ({ data }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const cardsData = [
    {
      icon: "/Images/Icons/analysed.png",
      count: data ? data[17].state : "loading",
      sub_text: "Analysed",
    },
    {
      icon: "/Images/Icons/invested.png",
      count: data ? data[18].state : "loading",
      sub_text: "Invested",
    },
    {
      icon: "/Images/Icons/exit.png",
      count: data ? data[19].state : "loading",
      sub_text: "Exits",
    },
    {
      icon: "/Images/Icons/capital.png",
      count: data ? data[20].state : "loading",
      sub_text: "Capital Deployed",
    },
  ];
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Fragment>
      <Box className={classes.PositionRelative} data-aos="fade-up">
        <img src={"/Images/Bg/BG8.jpg"} className={classes.backImg} />
        <div className={classes.textBox} data-aos="fade-up">
          <p className={classes.para}>A Pioneering way to</p>
          <p className={classes.sub_para}>
            <span className={classes.sub_para1}>approach&nbsp;</span>
            <span className={classes.sub_para2}>Investment</span>
          </p>
          <p className={classes.trade_mark_text}>
            {data ? data[10].state : "loading"}
          </p>
          <button onClick={() => {
            navigate("/about");
          }} className={classes.learn_more_btn}>Learn more</button>
        </div>
        <Grid container spacing={8} className={classes.cardContainer}>
          {cardsData.map((card, index) => (
            <Grid item xs={3} key={index} data-aos="fade-up">
              <div className={classes.card}>
                <img className={classes.card_icon} src={card.icon} alt="icon" />
                <p className={classes.card_count}>{card.count}</p>
                <p className={classes.card_sub_text}>{card.sub_text}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Fragment>
  );
};
