import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
//Firebase
import { firestore, storage } from "../../../../../Firebase";
//Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "30px",
  },
  Heading: {
    font: "normal normal 400 1.5vw Montserrat",
    margin: "2% 0",
  },
  inputField: {
    width: "15vw",
    height: "2.5rem",
    padding: "4%",
    borderRadius: "5px",
    border: "1px solid #000",
  },
  inputFieldFiles: {
    width: "15vw",
    height: "2.5rem",
    padding: "2%",
    borderRadius: "5px",
    display: "inline-block",
    content: "Select some files",
    border: "1px solid #000",
  },
  button: {
    width: "15vw",
    height: "2.5rem",
    padding: "4%",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#168ACB",
    color: "#fff",
    font: "normal normal bold 1rem Montserrat",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "5%",
      width: "5rem",
    },
  },
}));

export const AdvisorAddTeamMember = () => {
  const classes = useStyles();

  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));

  const [teamMember, setTeamMember] = useState({
    memberName: "",
    exprince: "",
    link: "",
    aboutUs: "",
    readMore: "",
  });

  const [profilePicture, setProfilePicture] = useState([]);

  const [logo, setLogo] = useState([]);

  const { memberName, exprince, link, aboutUs, readMore } = teamMember;

  const handleChangeProfile = (event) => {
    setProfilePicture(event.target.files[0]);
  };

  const handleChangeLogo = (event) => {
    setLogo(event.target.files[0]);
  };

  let name, value;

  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    setTeamMember({ ...teamMember, [name]: value });
  };

  const handleSubmitTiles = (event) => {
    event.preventDefault();

    let imageProfile = profilePicture;
    let imageLogo = logo;

    if (!memberName || !link || !aboutUs || !readMore) {
      toast.error("Please fill all the required fields !");
    } else {
      const uploadTaskProfile = storage
        .ref(`teamMember/${imageProfile.name}`)
        .put(profilePicture);

      const uploadTaskLogo = storage
        .ref(`teamMember/${imageLogo.name}`)
        .put(logo);

      uploadTaskProfile.on(
        "state_changed",
        (snapshot) => {
          const progressStatus = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          progressStatus === 0
            ? toast.info("pending")
            : toast.info(
                "Image is Uploading please wait for product data upload"
              );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("teamMember")
            .child(imageProfile.name)
            .getDownloadURL()
            .then((imageProfileURL) => {
              uploadTaskLogo.on(
                "state_changed",
                (snapshot) => {
                  const progressStatus = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  progressStatus === 0
                    ? toast.info("pending")
                    : toast.info(
                        "Image is Uploading please wait for product data upload"
                      );
                },
                (error) => {
                  console.log(error);
                },
                () => {
                  storage
                    .ref("teamMember")
                    .child(imageLogo.name)
                    .getDownloadURL()
                    .then((imageLogoURL) => {
                      firestore
                        .collection("aboutPageTeamQuain")
                        .doc()
                        .set({
                          memberName,
                          exprince,
                          link,
                          aboutUs,
                          readMore,
                          profileImage: imageProfileURL,
                          logoImage: imageLogoURL,
                        })
                        .then(() => {
                          toast.success("Successful.");

                          setTeamMember({
                            memberName: "",
                            exprince: "",
                            link: "",
                            aboutUs: "",
                            readMore: "",
                          });
                          setLogo([]);
                        })
                        .catch((err) => {
                          toast.error(err);
                        });
                    });
                }
              );
            });
        }
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.Heading}>Add New Team Member</h1>

      <form onSubmit={handleSubmitTiles}>
        <Grid container justifyContent="flex-start" spacing={4} xl={12}>
          <Grid item md={3}>
            Profile Picture *
            <br />
            <br />
            <input
              className={classes.inputFieldFiles}
              type="file"
              placeholder="logo"
              onChange={handleChangeProfile}
            />
          </Grid>
          <Grid item md={3}>
            Logo
            <br />
            <br />
            <input
              className={classes.inputFieldFiles}
              type="file"
              placeholder="logo"
              onChange={handleChangeLogo}
            />
          </Grid>
          <Grid item md={3}>
            Name
            <br />
            <br />
            <input
              className={classes.inputField}
              placeholder="Advisor Name"
              name="memberName"
              value={memberName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3}>
            Experience
            <br />
            <br />
            <input
              className={classes.inputField}
              placeholder="Experience"
              name="exprince"
              value={exprince}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3}>
            Linkedin link
            <br />
            <br />
            <input
              className={classes.inputField}
              placeholder="Enter link"
              name="link"
              value={link}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3}>
            Description-one
            <br />
            <br />
            <textarea
              className={classes.inputField}
              placeholder="Description"
              name="aboutUs"
              value={aboutUs}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3}>
            Description-two
            <br />
            <br />
            <textarea
              className={classes.inputField}
              placeholder="Description"
              name="readMore"
              value={readMore}
              onChange={handleChange}
              cols="10"
              rows="10"
            ></textarea>
          </Grid>

          <Grid item md={3}>
            Add
            <br />
            <br />
            <button className={classes.button}>Add</button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
