import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import "aos/dist/aos.css";
import aos from "aos";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  position: {
    width: "50%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20%",
      width: "100%",
    },
  },
  opa: {
    marginTop:"3%",
    textAlign:"left",
    color: "#168ACB",
    opacity: "0.1",
    font: "normal normal bold 10rem Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6rem",
    },
  },
  Head: {
    color: "#168ACB",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    position: "absolute",
    top: "15%",
    left: "8%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      top: "5%",
    },
  },
  marLeft: {
    marginLeft: "8%",
  },
  praCenter: {
    margin: "2% 0%",
    textAlign: "left",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "8vw",
    },
  },
  Image: {
    paddingLeft:"10%",
    width: "100%",
    height: "auto",
  },
}));

export const LeftFlex = ({ data }) => {
  const classes = useStyles();
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.position} data-aos="fade-up">
        <h1 className={classes.opa}>02</h1>
        <h1 className={classes.Head}>{data ? data[27].state : "loading"}</h1>

        <Box className={classes.marLeft}>
          <p className={classes.praCenter} data-aos="fade-up">
            {data ? data[34].state : "loading"}
          </p>
        </Box>
      </Box>

      <Box className={classes.position}>
        <img
          className={classes.Image}
          src={"./Images/Plateform/platform2.png"}
          alt="svg"
          data-aos="fade-up"
        />
      </Box>
    </Box>
  );
};
