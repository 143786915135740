import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { firestore } from "../../../../Firebase";
import { UpdateHomePop } from "./UpdateTilesPopup";
import { Video } from "./Video";
import { AdminCommonTable } from "../../../Molecule";

const active = {
  backgroundColor: "#fff",
  border: "1px solid black",
  color: "#000",
};

const unActive = {
  backgroundColor: "#168ACB",
  border: "1px solid #168ACB",
  color: "#fff",
};

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
    textAlign: "center",
  },
  portfolioInputBox: {
    border: "2px solid red",
  },
  button: {
    width: "8rem",
    border: "1px solid black",
    padding: "1%",
    "&:hover": {
      backgroundColor: "#168ACB",
      border: "1px solid #168ACB",
      color: "#fff",
    },
  },
}));

export const HomeDashboard = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState("");

  const [open, setOpen] = useState(false);

  const [HomeDetails, setHomeDetails] = useState("");

  const [documentID, setDocumentID] = useState("");

  const [Show, setShow] = useState("video");

  const handleClickOpen = (id) => {
    setOpen(true);
    setDocumentID(id);
    firestore
      .collection("homePage")
      .doc(id)
      .get()
      .then((snapshot) => {
        setHomeDetails(snapshot.data())
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (state) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("homePage")
        .doc(documentID)
        .update({ state })
        .then(() => {

          resolve();
          handleClose();
          toast.success("Update");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetch = () => {
    firestore
      .collection("homePage")
      .orderBy("state", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), documentID: doc.id });
        });
        setFetch(item);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div>
      <h1 className={classes.Heading}>Home Admin Dashboard</h1>
      <div
        style={{
          width: "30%",
          margin: "2% auto",
          display: "flex",
          justifyContent: "space-around",
          alignItemsL: "center",
        }}
      >
        <div
          className={classes.button}
          style={Show === "video" ? unActive : active}
        >
          <h1
            onClick={() => setShow("video")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Video
          </h1>
        </div>
        <div
          className={classes.button}
          style={Show === "HomeData" ? unActive : active}
        >
          <h1
            onClick={() => setShow("HomeData")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Data
          </h1>
        </div>
      </div>

      {Show === "video" ? <Video /> : ""}

      {Show === "HomeData" ? (
        <>
          <AdminCommonTable Data={fetch} handleClickOpen={handleClickOpen} />
          <UpdateHomePop
            open={open}
            handleClose={handleClose}
            HomeDetails={HomeDetails}
            handleUpdate={handleUpdate}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
