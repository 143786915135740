import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
//Firebase
import { firestore, storage } from "../../../../../Firebase";
//Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 400 1.5vw Montserrat",
    margin: "2% 0",
  },
  inputField: {
    width: "12vw",
    height: "2.5rem",
    padding: "4%",
    borderRadius: "10px",
    border: "1px solid #000",
  },
  inputFieldFiles: {
    width: "12vw",
    height: "2.5rem",
    padding: "2%",
    borderRadius: "10px",
    display: "inline-block",
    content: "Select some files",
    border: "1px solid #000",
  },
  button: {
    width: "12vw",
    height: "2.5rem",
    padding: "4%",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#168ACB",
    color: "#fff",
    font: "normal normal bold 1rem Montserrat",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "5%",
      width: "5rem",
    },
  },
}));

export const AddPortfolioTiles = () => {
  const classes = useStyles();

  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));

  const [portfolio, setPortfolio] = useState({
    companyName: "",
    sector: "",
    stage: "",
    link: "",
  });

  const [logo, setLogo] = useState([]);

  const { companyName, sector, stage, link } = portfolio;

  const handleChangeFile = (event) => {
    setLogo(event.target.files[0]);
  };

  let name, value;

  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    setPortfolio({ ...portfolio, [name]: value });
  };

  const handleSubmitTiles = (event) => {
    event.preventDefault();

    let image = logo;

    if (!companyName || !sector || !stage || !link || !logo) {
      toast.error("Please fill all the required fields!");
    } else {
      const uploadTask = storage
        .ref(`portfolioTilesLogo/${image.name}`)
        .put(logo);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressStatus = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          progressStatus === 0
            ? toast.info("pending")
            : toast.info("Image is uploading. Please wait.");
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("portfolioTilesLogo")
            .child(image.name)
            .getDownloadURL()
            .then((imageURL) => {
              firestore
                .collection("portolioPageCardsData")
                .doc()
                .set({
                  companyName,
                  sector,
                  stage,
                  link,
                  logo: imageURL,
                })
                .then(() => {
                  toast.success("Successful.");

                  setPortfolio({
                    companyName: "",
                    sector: "",
                    stage: "",
                    link: "",
                  });
                  setLogo([]);
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(err);
                });
            });
        }
      );
    }
  };

  return (
    <div>
      <h1 className={classes.Heading}>Add New Portfolio Tiles</h1>
      <h1
        className={classes.Heading}
        style={{ color: "red", fontSize: "1.1vw" }}
      >
        Note* Logo Image Size Should be (Width-120px Height-50px)
      </h1>
      <form onSubmit={handleSubmitTiles}>
        <Grid container justifyContent="space-between" xl={12}>
          <Grid item>
            <input
              className={classes.inputFieldFiles}
              type="file"
              placeholder="logo"
              onChange={handleChangeFile}
            />
          </Grid>
          <Grid item>
            <input
              className={classes.inputField}
              placeholder="Company Name"
              name="companyName"
              value={companyName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <input
              className={classes.inputField}
              placeholder="Sector"
              name="sector"
              value={sector}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <input
              className={classes.inputField}
              placeholder="Link"
              name="link"
              value={link}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <select
              className={classes.inputField}
              name="stage"
              value={stage}
              onChange={handleChange}
            >
              <option>Selected</option>
              <option value="Early">Early</option>
              <option value="Growth">Growth</option>
            </select>
          </Grid>
          <Grid item>
            <button className={classes.button}>Add</button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
