import React, { Fragment, useEffect } from "react";
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
// Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    background: "#2D2D2D",
    height: "500px",
    borderRadius: "20px",
    margin: "0 25px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "-80px",
    },
  },
  headerText: {
    display: "inline-block",
    fontSize: "32px",
    fontFamily: "PlayfairDisplay-Regular",
    fontWeight: "700",
    textAlign: "center",
    marginTop: "80px",
    marginBottom: "120px",
    color: "#171616",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "120px",
    },
  },
  modelText: {
    fontSize: "32px",
    fontFamily: "PlayfairDisplay-Regular",
    fontWeight: "700",
    textAlign: "left",
    marginTop: "80px",
    marginBottom: "50px",
    padding: "25px",
    color: "#171616",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "50px",
    },
  },
  contentText: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "500",
    textAlign: "left",
    color: "#FFFDFD",
    padding: "0px 10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
    },
  },
  textContainer: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20px",
    },
  },
  imageContainer: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    borderRadius: "20px",
  },
  mainBox: {
    width: "97%",
    margin: "0% auto 5% auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "-15%",
    },
  },
  leftBox: {
    width: "100%",
    "& img": {
      width: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightBox: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  HeadCenter: {
    textAlign: "center",
    font: "normal normal bold 3rem Montserrat",
    color: "#33333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      marginTop: "5%",
    },
  },
  praCenter: {
    width: "65%",
    margin: "0 auto",
    font: "normal normal normal 2vw Montserrat",
    lineHeight: "1.4",
    textAlign: "center",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: "10% 0",
      textAlign: "justify",
    },
  },
  analytics: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h1": {
      opacity: ".3",
      fontWeight: "bolder",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  boxes: {
    width: "28%",
    height: "20vw",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
      height: "20vh",
      padding: "auto .5rem",
    },
  },
  imageBox: {
    width: "4vw",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "8vw",
    },
  },
  model_image: {
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
    padding: "2px",
    borderRadius: "20px",
  },
  model_text: {
    marginTop: "0px",
    fontSize: "20px",
    fontFamily: "Nunito-Regular",
    padding: "12px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      textAlign: "center",
      marginTop: "-5px",
    },
  },
  overlay: {
    width: "calc(100% - 70px)",
    height: "calc(100% - 150px)",
    backgroundColor: "#FFFDFD",
    borderRadius: "20px",
    opacity: 0.9,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      top: "58%",
      flexDirection: "column",
    },
  },
  rightContent: {
    flex: 1,
    padding: "20px",
  },
  leftContent: {
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  rightContentText: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "600",
    textAlign: "left",
    marginTop: "130px",
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  leftContentImg: {
    width: "100%",
    height: "auto",
    borderRadius:"20px",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "100%",
      marginTop: "-10px",
    },
  },
  '@media (max-width: 1367px)': {
  rightContentText:{
    marginTop:"100px"
  }
  }
}));

export const Offerings = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Fragment>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <p className={classes.headerText} data-aos="fade-up">
          <span className="position-relative">
            {data ? data[13].state : "loading"}
          </span>
          <div className="highlighter"></div>
        </p>
      </div>
      <div className={classes.overlay}>
        <div className={classes.leftContent} data-aos="fade-right">
          <img
            className={classes.leftContentImg}
            src="./Images/Home/home_offer.jpg"
            alt=""
          />
        </div>
        <div className={classes.rightContent} data-aos="fade-up">
          <p className={classes.rightContentText} data-aos="fade-right">
            <q>{data ? data[5].state : "loading"}</q>
          </p>
        </div>
      </div>
      <p className={classes.modelText} data-aos="fade-up">
        Our Model
      </p>
      <Box className={classes.mainBox}>
        <Box className={classes.analytics}>
          <Box className={classes.boxes} data-aos="fade-up">
            <img
              className={classes.model_image}
              src="/Images/About/analytics.png"
              alt="analytics"
            />
            <div
              style={{
                position: "absolute",
                width: "101%",
                top: "90%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "rgba(255, 255, 255, 0.8)",
                padding: "5px",
                minHeight: "79px",
              }}
            >
              <p className={classes.model_text}>Diversified Sourcing Network</p>
            </div>
          </Box>

          <h1 className={classes.small} data-aos="fade-up">
            +
          </h1>
          <Box className={classes.boxes} data-aos="fade-up">
            <img
              className={classes.model_image}
              src="/Images/About/network.jpg"
              alt="network"
            />
            <div
              style={{
                position: "absolute",
                width: "101%",
                top: "90%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "rgba(255, 255, 255, 0.8)",
                padding: "5px",
                minHeight: "79px",
              }}
            >
              <p className={classes.model_text}>
                Quain Business Intelligence Platform
              </p>
            </div>
          </Box>

          <h1 className={classes.small} data-aos="fade-up">
            =
          </h1>

          <Box
            className={classes.boxes}
            data-aos="fade-up"
            style={{
              position: "relative",
            }}
          >
            <img
              className={classes.model_image}
              src="/Images/About/stock.jpg"
              alt="stock"
            />
            <div
              style={{
                position: "absolute",
                width: "101%",
                top: "90%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "rgba(255, 255, 255, 0.8)",
                padding: "5px",
                minHeight: "79px",
              }}
            >
              <p className={classes.model_text}>
                Institutional Stewardship with better Outcomes
              </p>
            </div>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
