import React from "react";
//Material Ui
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
  },
}));

export const NewsTable = ({ data, handleDeleteNews, handleClickOpen }) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ marginTop: "1rem" }}>
      <h1 className={classes.Heading}>News Table</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sr No</TableCell>
            <TableCell>Date and Time</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ? data.map((item, index) => {
                const {
                  dateandtime,
                  title,
                  category,
                  link,
                  image,
                  documentID,
                } = item;
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{dateandtime}</TableCell>
                    <TableCell>{title}</TableCell>
                    <TableCell>{category}</TableCell>
                    <TableCell>{link}</TableCell>
                    <TableCell>
                      <img style={{ width: "8rem" }} src={image} alt="image" />
                    </TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickOpen(documentID)}
                    >
                      Edit
                    </TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteNews(documentID)}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                );
              })
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
