import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  HeadCenter: {
    font: "normal normal bold 1.8vw Montserrat",
    color: "#168ACB",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
  },
  praCenter: {
    font: "normal normal normal 1.5vw Montserrat",
    width: "95%",
    margin: "0% auto",
    marginTop: "2%",
    marginBottom: "3%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1.1rem",
    },
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    margin: "50px 10px",
    textAlign: "center",
  },
  img_container: {
    width: "100%",
    textAlign: "center",
    marginBottom: "3%",
  },
  img: {
    width: "95%",
    height: "auto",
    borderRadius: "20px"
  },
  "@media (max-width: 1367px)": {
    img: {
      marginBottom: "30px",
    },
  },
}));

export const QuainBrain = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <div style={{
      textAlign: "center"
    }}>
      <p className={classes.mainHeader}>
        <span className={`position-relative`}>
          {data ? data[2].state : "loading"}
        </span>
        <div className="highlighter"></div>
      </p>
      <h1 className={classes.HeadCenter} data-aos="fade-up">
        {data ? data[1].state : "loading"}
      </h1>
      <p className={classes.praCenter} data-aos="fade-up">
        {data ? data[3].state : "loading"}
      </p>
      <div data-aos="fade-up" className={classes.img_container}>
        <img
          className={classes.img}
          src={"/Images/Social/social_responsibility.jpg"}
        />
      </div>
    </div>
  );
};
