import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textContainer: {
    padding: "50px 100px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      padding: "50px 50px",
    },
  },
  image: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  header: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    textAlign: "center",
    borderBottom: `5px solid Green`,
  },
  header_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  content_header: {
    fontFamily: "Roboto-Regular",
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "20px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      textAlign: "center",
    },
  },
  subscription_text: {
    fontFamily: "Nunito-Regular",
    fontSize: "40px",
    fontWeight: "700",
    color: "#171616",
  },
  subscription_sub_text: {
    fontFamily: "Nunito-Regular",
    fontSize: "24px",
    fontWeight: "500",
    color: "#171616",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  button: {
    width: "451px",
    borderRadius: "6px",
    padding: "12px 22px 13px 21px",
    background:
      "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
    border: "none",
    color: "#fff",
    fontFamily: "Nunito-Regular",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  email_input: {
    height: "63px",
    width: "451px",
    borderRadius: "10px",
    border: "1px solid #00DFC0",
    background: "#FFF",
    padding: "10px",
    marginTop: "50px",
    marginBottom: "30px",
    fontFamily: "Nunito-Regular",
    fontSize: "20px",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "@media (max-width: 1367px)": {
    image: {
      marginTop: "-36px",
    },
  },
  errorText: {
    color: "red",
    marginLeft: "5px",
    marginBottom:"10px",
    fontSize: "15px",
    marginTop: "-25px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
  },
}));

export const Subscription = ({ data }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const handleSubscription = () => {
    if (email == "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <div className={classes.container} data-aos="fade-up">
      <Grid container alignItems="center" className={classes.container}>
        <Grid item xs={12} sm={6} data-aos="fade-right">
          <img src="/Images/Bg/BG12.jpg" className={classes.image} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.textContainer}
          data-aos="fade-up"
        >
          <p className={classes.subscription_text}>Subscribe to our</p>
          <p className={classes.subscription_text}>newsletter</p>
          <p className={classes.subscription_sub_text}>
            Be the first to receive any new updates
          </p>
          <input
            placeholder="Enter your email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className={classes.email_input}
          />
          {error == true && email == "" && (
            <p className={classes.errorText}>Email is Required</p>
          )}

          <button
            onClick={() => {
              handleSubscription();
            }}
            type="submit"
            className={classes.button}
          >
            SUBSCRIBE
          </button>
        </Grid>
      </Grid>
    </div>
  );
};
