import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { AddPortfolioTiles } from "./AddProtfolioTiles";
import { PortfolioTable } from "./PortfolioTilesTable";
import { PortfolioTables } from "./portfolioTables";
import { toast } from "react-toastify";
import { firestore, storage } from "../../../../Firebase";
import { UpdateHomePop } from "./UpdateTilesPopup";

const active = {
  backgroundColor: "#fff",
  border: "1px solid black",
  color: "#000",
};

const unActive = {
  backgroundColor: "#168ACB",
  border: "1px solid #168ACB",
  color: "#fff",
};

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
    textAlign: "center",
  },
  portfolioInputBox: {
    border: "2px solid red",
  },
  button: {
    width: "8rem",
    border: "1px solid black",
    padding: "1%",
    "&:hover": {
      backgroundColor: "#168ACB",
      border: "1px solid #168ACB",
      color: "#fff",
    },
  },
}));

export const PortFolioDashboard = () => {
  const classes = useStyles();

  const [fetchTiles, setFetchTiles] = useState("");

  const [state, setState] = useState("");

  const [HomeDetails, setHomeDetails] = useState("");

  const [fetchportfolio, setFetchportfolio] = useState("");

  const [Show, setShow] = useState("data");

  const currentDate = new Date().toISOString();

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [documentID, setDocumentID] = useState("");
  const [tileDetails, setTileDetails] = useState({
    companyName: "",
    sector: "",
    link: "",
  });

  const handleClickOpen = (id) => {

    setOpen(true);
    setDocumentID(id);
    firestore
      .collection("portolioPageCardsData")
      .doc(id)
      .get()
      .then((snapshot) => {

        let dssd = snapshot.data()
        setTileDetails({
          companyName: snapshot.data().companyName,
          sector: snapshot.data().sector,
          link: snapshot.data().link,
        })
      }

      );
  };

  const handleClickOpens = (id) => {
    setOpen(true);
    setDocumentID(id);
    firestore
      .collection("portfolioPageContent")
      .doc(id)
      .get()
      .then((snapshot) => setHomeDetails(snapshot.data()));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleFetchTiles = () => {
    firestore
      .collection("portolioPageCardsData")
      .orderBy("companyName", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), documentID: doc.id });
        });
        setFetchTiles(item);
      });
    // .get()
    // .then((snapshot) => {
    //     const GetTilesData = snapshot.docs.map((doc) => {
    //         return {
    //             ...doc.data(),
    //             documentID: doc.id
    //         }
    //     })
    //     if (GetTilesData) {
    //         setFetchTiles(GetTilesData)
    //     } else {
    //         setFetchTiles("No data Found")
    //     }
    // })
    // .catch((error) => {
    //     console.log(error)
    // })
  };

  const handleFetchportfolio = () => {
    firestore
      .collection("portfolioPageContent")
      .get()
      .then((snapshot) => {
        const GetTilesData = snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            documentID: doc.id,
          };
        });
        if (GetTilesData) {
          setFetchportfolio(GetTilesData);
        } else {
          setFetchportfolio("No data Found");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateTiles = (state) => {
    const { companyName, sector, stage, logo, link } = state;
    return new Promise((resolve, reject) => {
      handleClose();

      let image = logo;

      const uploadTask = storage
        .ref(`portfolioTilesLogo/${image.name}`)
        .put(logo);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressStatus = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          progressStatus === 0
            ? toast.info("pending")
            : toast.info(
              "Image is Uploading please wait for product data upload"
            );
        },
        (error) => {
          toast.error(error);
        },
        () => {
          storage
            .ref("portfolioTilesLogo")
            .child(image.name)
            .getDownloadURL()
            .then((imageURL) => {
              firestore
                .collection("portolioPageCardsData")
                .doc(documentID)
                .update({
                  companyName,
                  sector,
                  stage,
                  link,
                  logo: imageURL,
                })
                .then(() => {
                  resolve();
                  handleClose();
                  toast.success("Tile Update");
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(err);
                });
            });
        }
      );
    });
  };

  const handleUpdate = (state) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("portfolioPageContent")
        .doc(documentID)
        .update({ state })
        .then(() => {
          resolve();
          handleClose();
          handleFetchportfolio();
          toast.success("Updated");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    handleFetchTiles();
    handleFetchportfolio();
  }, []);

  const handleDeleteTiles = (documentID) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("portolioPageCardsData")
        .doc(documentID)
        .delete()
        .then((success) => {
          toast.success("Tile Deleted");
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // const handleSubmit = (e) => {
  //     e.preventDefault()
  //     firestore
  //         .collection("portfolioPage")
  //         .doc()
  //         .set({ state, currentDate })
  //         .then((success) => {
  //             toast.success("Added")
  //         })
  //         .catch(err => {
  //             console.log(err)
  //         });
  // }

  return (
    <>
      <div className={classes.root}>
        <h1 className={classes.Heading}>Portfolio Admin Dashboard </h1>
        {/*<form onSubmit={handleSubmit}>
                <input placeholder='dalo' onChange={(e) => setState(e.target.value)} />
                <button type='submit'>Click Me</button>
    </form>*/}

        <div
          style={{
            width: "30%",
            margin: "2% auto",

            display: "flex",
            justifyContent: "space-around",
            alignItemsL: "center",
          }}
        >
          <div
            className={classes.button}
            style={Show === "data" ? unActive : active}
          >
            <h1
              onClick={() => setShow("data")}
              className={classes.Heading}
              style={{ cursor: "pointer" }}
            >
              Data
            </h1>
          </div>
          <div
            className={classes.button}
            style={Show === "tiles" ? unActive : active}
          >
            <h1
              onClick={() => setShow("tiles")}
              className={classes.Heading}
              style={{ cursor: "pointer" }}
            >
              Tiles
            </h1>
          </div>
        </div>

        <div style={{ padding: "30px" }}>
          {Show === "data" ? (
            <>
              <PortfolioTables
                data={fetchportfolio}
                handleClickOpens={handleClickOpens}
              />
              <UpdateHomePop
                open={open}
                handleClose={handleClose}
                HomeDetails={HomeDetails}
                handleUpdate={handleUpdate}
              />
            </>
          ) : (
            ""
          )}

          {Show === "tiles" ? (
            <>
              <AddPortfolioTiles />
              <PortfolioTable
                tilesData={fetchTiles}
                deleteTile={handleDeleteTiles}
                open={open}
                handleClose={handleClose}
                handleClickOpen={handleClickOpen}
                handleUpdateTiles={handleUpdateTiles}
                tileDetails={tileDetails}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
