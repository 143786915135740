import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  inputField: {
    padding: "4%",
    width: "12rem",
    borderRadius: "10px",
    border: "1px solid #000",
  },
  inputFieldFiles: {
    padding: "2%",
    width: "12rem",
    borderRadius: "10px",
    display: "inline-block",
    content: "Select some files",
    border: "1px solid #000",
  },
  button: {
    width: "12rem",
    padding: "4%",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#168ACB",
    color: "#fff",
    font: "normal normal bold 1rem Montserrat",
    cursor: "pointer",
    marginRight: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "5%",
      width: "5rem",
    },
  },
}));

export const NewsUpdate = ({
  open,
  handleClose,
  NewsDetails,
  handleUpdate,
}) => {
  const classes = useStyles();

  const [news, setNews] = useState({
    dateandtime: "",
    title: "",
    category: "",
    link: "",
  });

  const [image, setImage] = useState([]);

  const { dateandtime, title, category, link } = news;

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
  };

  let name, value;

  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    setNews({ ...news, [name]: value });
  };

  const state = { dateandtime, title, category, link, image };

  const handleNewsUpdate = (e) => {
    e.preventDefault();
    if (!dateandtime || !title || !category || !link) {
      toast.error("Please fill the input field");
    } else {
      handleUpdate(state);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ textAlign: "center" }}>Update News</DialogTitle>
      <DialogContent>
        <form onSubmit={handleNewsUpdate}>
          <input
            className={classes.inputField}
            placeholder={
              NewsDetails ? NewsDetails.dateandtime : "Date and Time"
            }
            name="dateandtime"
            value={dateandtime}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            className={classes.inputField}
            placeholder={NewsDetails ? NewsDetails.title : "Title"}
            name="title"
            value={title}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            className={classes.inputField}
            placeholder={NewsDetails ? NewsDetails.category : "Category"}
            name="category"
            value={category}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            className={classes.inputField}
            placeholder={NewsDetails ? NewsDetails.link : "Link"}
            name="link"
            value={link}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            className={classes.inputFieldFiles}
            type="file"
            placeholder="image"
            onChange={handleChangeFile}
          />
          <br />
          <br />
          <button className={classes.button} type="submit">
            Update
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
