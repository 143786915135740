import React, { Fragment, useEffect } from "react";
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
// Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    backgroundPositionY: "center",
    opacity: "0.3",
    position: "fixed",
    [theme.breakpoints.down("sm")]: {
      height: "200vh",
    },
  },
  header_text: {
    display: "inline-block",
    fontSize: "32px",
    fontFamily: "Roboto-Regular",
    fontWeight: "500",
    textAlign: "center",
    paddingTop: "65px",
    color: "#171616",
  },
  content_text: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "500",
    textAlign: "center",
    color: "#171616",
    marginTop: "50px",
    padding: "0px 25px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
    },
  },
  Image: {
    width: "100%",
    height: "100%",
    marginTop: "-302px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50px",
      marginLeft: "0px",
    },
  },
  button: {
    width: "10rem",
    borderRadius: "6px",
    padding: "12px 22px 13px 21px",
    background:
      "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
    border: "none",
    color: "#fff",
    fontFamily: "Nunito-Regular",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    marginBottom: "5%",
  },
  image_container: {
    background: "rgba(255, 255, 255, 0.8)",
    width: "90%",
    height: "auto",
    borderRadius: "10px",
    margin: "0 auto",
    textAlign: "center",
    border: "2px solid #168ACB",
  },
  grid_container: {
    width: "100%",
    margin: "0 auto",
  },
  img: {
    width: "100%",
    height: "auto",
    minHeight: "273px",
    borderRadius: "4px",
    padding: "10px",
    maxHeight: "273px"
  },
}));

export const AboutPortfolio = ({ data }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box className={classes.container} data-aos="fade-up">
      <img
        className={classes.backgroundImage}
        src="/Images/Bg/BG13.png"
        alt="Background"
      />
      <div style={{
        textAlign: "center"
      }}>
        <p className={classes.header_text} data-aos="fade-up">
          <span className="position-relative">
            {data ? data[6].state : "loading"}
          </span>
          <div className="highlighter"></div>
        </p>
      </div>
      <p className={classes.content_text} data-aos="fade-up">
        {data ? data[9].state : "loading"}
      </p>
      <div
        data-aos="fade-up"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <Grid
          container
          spacing={3}
          justify="center"
          className={classes.grid_container}
        >
          <Grid item xs={12} sm={3}>
            <div className={classes.image_container}>
              <img
                className={classes.img}
                src={"/Images/About/saving.jpeg"}
                alt="saving"
              />
              <p style={{ textAlign: "center", padding: "2%" }}>Early Stage</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.image_container}>
              <img
                className={classes.img}
                src={"/Images/About/saving1.jpg"}
                alt="saving1"
              />
              <p style={{ textAlign: "center", padding: "2%" }}>Institutional Stewardship</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.image_container}>
              <img
                className={classes.img}
                src={"/Images/About/fintech.png"}
                alt="fintech"
              />
              <p style={{ textAlign: "center", padding: "2%" }}>Sector Agnostic</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.image_container}>
              <img
                className={classes.img}
                src={"/Images/About/networking.jpg"}
                alt="networking"
              />
              <p style={{ textAlign: "center", padding: "2%" }}>Diversification</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        data-aos="fade-up"
        style={{
          textAlign: "center",
        }}
      >
        <button
          onClick={() => {
            navigate("/portfolio");
          }}
          className={classes.button}
        >
          View Portfolio
        </button>
      </div>
    </Box>
  );
};
