import React, { useEffect, useState } from "react";
import { Intro } from "./Intro";
import { Card } from "./Card";
import { TeamQuain } from "./TeamQuain";
import { TeamCard } from "./TeamCard";
import { Box } from "@material-ui/core";
import { ScrollTop } from "../../Molecule/ScrollToTop";
import { firestore } from "../../../Firebase";
import { Collaboration } from "./Collaboration";
import { Subscription } from "./Subscription";
import { Offerings } from "./Offerings";
import { AboutPortfolio } from "./AboutPortfolio";

export const Home = () => {
  const [fetch, setFetch] = useState("");
  const [logo, setLogo] = useState("");
  const handleFetch = () => {
    firestore.collection("homePage").onSnapshot((querySnapshot) => {
      const itemdummy = [];
      itemdummy.length = [querySnapshot.docs.length];
      querySnapshot.forEach((doc) => {
        if (doc.id === "2fVulUMc1rWwPF9IPKuF")
          itemdummy[0] = doc.data();
        else if (doc.id === "AsIGPHzPSzrIUQOeZq7N")
          itemdummy[1] = doc.data();
        else if (doc.id === "NOlS9pafDb161714lp2R")
          itemdummy[2] = doc.data();
        else if (doc.id === "O6b0qnzIdsnJ5kcof9av")
          itemdummy[3] = doc.data();
        else if (doc.id === "T3fIVB8QfaE0IF7xmgHE")
          itemdummy[4] = doc.data();
        else if (doc.id === "UQ9REpIYLu7zHvpn6OM4")
          itemdummy[5] = doc.data();
        else if (doc.id === "ZtNO6iSn1rGtzVCYhWFD")
          itemdummy[6] = doc.data();
        else if (doc.id === "hDyz7YZgpu0jfwGoOlaa")
          itemdummy[7] = doc.data();
        else if (doc.id === "mevV1nlWciqz7zDBgBaD")
          itemdummy[8] = doc.data();
        else if (doc.id === "mgrn7MNfP1a6L2d8LfSN")
          itemdummy[9] = doc.data();
        else if (doc.id === "nir37mDKQcdFLLxkfTIi")
          itemdummy[10] = doc.data();
        else if (doc.id === "vFIDm8VYOwzDxFFGG9zs")
          itemdummy[11] = doc.data();
        else if (doc.id === "wQFbGAMTLncVKL3IeDuu")
          itemdummy[12] = doc.data();
        else if (doc.id === "wgvv72XuzzVE1PWwGUqa")
          itemdummy[13] = doc.data();
        else if (doc.id === "xvIM9dd8nUQJ4mKyGAH8")
          itemdummy[14] = doc.data();
        else if (doc.id === "BjTcpdS4Tl82LroVnBn3")
          itemdummy[15] = doc.data();

        else if (doc.id === "yBVBPsHIWFWBrwEePkB4")
          itemdummy[16] = doc.data();
        else if (doc.id === "y1hFBq3HkvC82d85NHKh")
          itemdummy[17] = doc.data();
        else if (doc.id === "h6d9YSkEE4HXlpoHiBKI")
          itemdummy[18] = doc.data();
        else if (doc.id === "4IiF7mA31956TOsyi4ef")
          itemdummy[19] = doc.data();
        else if (doc.id === "P2eh1u29ta1Ke4pVSJvG")
          itemdummy[20] = doc.data();
      });
      setFetch(itemdummy);
    });
    firestore.collection("companyLogos").onSnapshot((querySnapshot) => {
      const itemdummy = [];
      querySnapshot.forEach((doc) => {
        itemdummy.push(doc.data())
      });

      setLogo(itemdummy);
    });
  };

  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
    handleFetch();
  }, []);

  return (
    <Box style={{ overflow: "hidden" }}>
      <ScrollTop />
      <Intro data={fetch} />
      <Collaboration data={fetch} logo={logo} />
      <Card data={fetch} />
      <TeamQuain data={fetch} />
      <TeamCard data={fetch} />
      <Offerings data={fetch} />
      <AboutPortfolio data={fetch} />
      <Subscription data={fetch} />
    </Box>
  );
};
