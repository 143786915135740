import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { collection, onSnapshot } from "firebase/firestore";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
import { firestore } from "../../../../Firebase";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textContainer: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "12px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  header: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    textAlign: "center",
  },
  header_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  content_header: {
    fontFamily: "Roboto-Regular",
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "20px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      textAlign: "center",
    },
  },
  content_1: {
    fontFamily: "Nunito-Regular",
    fontSize: "24px",
    fontWeight: "400",
    color: "#2D2D2D",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      textAlign: "justify",
    },
  },
  learn_more: {
    fontFamily: "Nunito-Regular",
    fontSize: "20px",
    fontWeight: "500",
    color: "#05992F",
    margin: "20px 0px 60px 0px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      textAlign: "center",
    },
  },
  video: {
    width: "80%",
    height: "auto",
    border: "4px solid #0000001A",
    borderRadius: "60px"
  },
  video_container: {
    margin: "5% auto",
    textAlign: "center",
    width: "80%",
    height: "auto",
  },
}));

export const Card = ({ data }) => {
  const classes = useStyles();
  const vidRef = useRef(null);
  const [fetch, setFetch] = useState([]);

  const handlePlay = () => {
    vidRef.current.play();
  };
  const handlePause = () => {
    vidRef.current.pause();
  };
  const handleToggleVideo = () =>
    vidRef.current.paused ? handlePlay() : handlePause();

  const handleFetch = () => {
    const videoCollectionRef = collection(firestore, "video");
    const unsubscribe = onSnapshot(videoCollectionRef, (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setFetch(items);
    });
    return () => unsubscribe();
  };

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
    handleFetch();
  }, []);
  const navigate = useNavigate();
  return (
    <div className={classes.container} data-aos="fade-up">
      <div className={classes.video_container}>
        <video
          controls
          ref={vidRef}
          className={classes.video}
          poster={"/Images/Home/poster.png"}
          data-aos="fade-right"
          data-aos-delay="200"
          autoPlay
          loop
          muted
        >
          {fetch
            ? fetch.map((item, index) => {
              return <source src={item.video} key={index} />;
            })
            : ""}
        </video>
      </div>
      <div className={classes.header_container}>
        <p className={classes.header}>
          <span className="position-relative">About Us</span>
          <div className="highlighter"></div>
        </p>
      </div>
      <Grid container alignItems="center" className={classes.container}>
        <Grid
          item
          xs={12}
          sm={7}
          className={classes.textContainer}
          data-aos="fade-up"
        >
          <p className={classes.content_header}>Who are we?</p>
          <p className={classes.content_1}>
            {data ? data[4].state : "loading"}
          </p>
          <p onClick={() => {
            navigate('/about');
          }} className={classes.learn_more}>Learn More</p>
          <p className={classes.content_header}>
            {" "}
            {data ? data[0].state : "loading"}?
          </p>
          <p className={classes.content_1}>
            {data ? data[8].state : "loading"}
          </p>
          <p onClick={() => {
            navigate('/about');
          }} className={classes.learn_more}>Learn More</p>
        </Grid>
        <Grid item xs={12} sm={5} data-aos="fade-right">
          <img src="/Images/About/team.jpg" className={classes.image} />
        </Grid>
      </Grid>
    </div>
  );
};
