import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    margin: "50px 10px",
    textAlign: "center",
    margin: "10% 0 2% 0%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  CardBoxRadius: {
    width: "100%",
    margin: "0% 0% 0% 8%",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "25%",
      width: "90%",
    },
  },
  card: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20%",
    },
  },
  cardright: {
    float: "left",
    display: "inline-block",
    width: "83%",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20%",
    },
  },
  image: {
    width: "25%",
  },
  rightImage: {
    width: "18%",
    marginTop: "-20%",
    float: "right",
  },
  content: {
    marginLeft: "35%",
    marginTop: "-15%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
      marginLeft: "0%",
    },
  },
  rightcontent: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
      marginLeft: "0%",
    },
  },
  head: {
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    color: "#168ACB",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
    },
  },
  subHead: {
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight:"600",
    margin: "2% 0%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
      marginTop: "5%",
    },
  },
  para: {
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
      marginTop: "5%",
    },
  },
}));

export const Card = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <p className={classes.mainHeader}>
          <span className={`position-relative`}>
            {data ? data[9].state : "Loading"}
          </span>
          <div className="highlighter"></div>
        </p>
      </div>
      <Box className={classes.CardBoxRadius}>
        <Box className={classes.card} data-aos="fade-right">
          <img
            className={classes.image}
            src={"./Images/About/01.svg"}
            alt="id"
          />
          <Box className={classes.content}>
            <h1 className={classes.head}>{data ? data[0].state : "Loading"}</h1>
            <p className={classes.subHead}>
              <q>{data ? data[11].state : "Loading"}</q>
            </p>
            <p className={classes.para}>{data ? data[1].state : "Loading"}</p>
          </Box>
        </Box>
        <Box className={classes.cardright} style={{
          marginTop:"15%"
        }} data-aos="fade-left">
          <Box className={classes.rightcontent}>
            <h1 className={classes.head}>{data ? data[3].state : "Loading"}</h1>
            <p className={classes.subHead}>
              {data ? data[8].state : "Loading"}
            </p>
            <p className={classes.para}>{data ? data[4].state : "Loading"}</p>
          </Box>
            <img
              className={classes.rightImage}
              src={"./Images/About/02.svg"}
              alt="id"
            />
        </Box>

        <Box className={classes.card} style={{
          marginTop:"38%",
          marginBottom:"10%",
        }} data-aos="fade-right">
          <img
            className={classes.image}
            src={"./Images/About/03.svg"}
            alt="id"
          />
          <Box className={classes.content}>
            <h1 className={classes.head}>{data ? data[6].state : "Loading"}</h1>
            <p className={classes.subHead}>
              {data ? data[10].state : "Loading"}
            </p>
            <p className={classes.para}>{data ? data[11].state : "Loading"}</p>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// {
//     CardData.map((item) => {
//         const { id, head, para1, para2, background } = item
//         return (
//             <Box className={classes.CardBox} style={{ backgroundColor: `${background}` }}>
//                <h1 className={classes.para2Style}>#{id}</h1>
//                 <br />
//                 <h1 className={classes.headStyle}>{head}</h1>
//                 <br />
//                 <h1 className={classes.para1Style}>{para1}</h1>
//                 <br />
//                 <h1 className={classes.para3Style}>{para2}</h1>
//             </Box>
//         )
//     })
// }
