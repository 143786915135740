import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 400 1.5vw Montserrat",
    margin: "2% 0",
  },
  inputField: {
    width: "12vw",
    height: "2.5rem",
    padding: "4%",
    borderRadius: "10px",
    border: "1px solid #000",
  },
  inputFieldFiles: {
    width: "12vw",
    height: "2.5rem",
    padding: "2%",
    borderRadius: "10px",
    display: "inline-block",
    content: "Select some files",
    border: "1px solid #000",
  },
  button: {
    width: "12vw",
    height: "2.5rem",
    padding: "4%",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#168ACB",
    color: "#fff",
    font: "normal normal bold 1rem Montserrat",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "5%",
      width: "5rem",
    },
  },
}));

export const AddBlog = ({ handleSubmitBlog }) => {
  const classes = useStyles();
  const [newsAndBlog, setNewsAndBlog] = useState({
    dateandtime: "",
    title: "",
    category: "",
    link: "",
  });

  const [image, setImage] = useState([]);

  const { dateandtime, title, category, link } = newsAndBlog;

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
  };

  let name, value;

  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    setNewsAndBlog({ ...newsAndBlog, [name]: value });
  };

  const state = { dateandtime, title, category, link, image };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!dateandtime || !title || !category || !link || !image) {
      toast.error("fill all input field");
    } else {
      handleSubmitBlog(state);
    }
  };

  return (
    <div>
      <h1 className={classes.Heading}>Add Blog</h1>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="space-between" xl={12}>
          <Grid item>
            <input
              className={classes.inputFieldFiles}
              type="file"
              placeholder="image"
              onChange={handleChangeFile}
            />
          </Grid>
          <Grid item>
            <input
              className={classes.inputField}
              placeholder="Date and time"
              name="dateandtime"
              value={dateandtime}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <input
              className={classes.inputField}
              placeholder="Category"
              name="category"
              value={category}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <textarea
              className={classes.inputField}
              placeholder="Title"
              name="title"
              value={title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <input
              className={classes.inputField}
              placeholder="Link"
              name="link"
              value={link}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <button className={classes.button}>Add</button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
