import React, { useEffect, useState } from "react";
import { Intro } from "./Intro";
import { AboutSection } from "./AboutSection";
import { Card } from "./Card";
import { TeamQuain } from "./TeamQuain";
import { AdvisorCard } from "./AdvisorCard";
import { Box } from "@material-ui/core";
import { ScrollTop } from "../../Molecule/ScrollToTop";
import { firestore } from "../../../Firebase";

export const About = () => {
  const [fetch, setFetch] = useState("");
  const [fetchTeam, setFetchTeam] = useState("");
  const [fetchAdvisors, setFetchAdvisors] = useState("");
  const [fetchTeamMember, setFetchTeamMember] = useState("");
  const [fetchAdvisorsMember, setFetchAdvisorsMember] = useState("");

  const handleFetch = () => {
    firestore.collection("aboutPage").onSnapshot((querySnapshot) => {
      const item = [];
      querySnapshot.forEach((doc) => {
        item.push(doc.data());
      });
      setFetch(item);
    });
  };

  const handleFetchTeamMember = () => {
    firestore
      .collection("aboutPageTeamQuain")
      .orderBy("memberName", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), id: doc.id });
        });
        setFetchTeamMember(item);
      });
  };

  const handleFetchAdvisorMember = () => {
    firestore
      .collection("AdvisorteamMemberQuain")
      .orderBy("memberName", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), id: doc.id });
        });
        setFetchAdvisorsMember(item);
      });
  };

  const handleFetchTeam = () => {
    firestore
      .collection("teamMemberQuain")
      .orderBy("state", "desc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push(doc.data());
        });
        setFetchTeam(item);
      });
  };

  const handleFetchAdvisors = () => {
    firestore
      .collection("aboutPageTeamAdvisors")
      .orderBy("state", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push(doc.data());
        });
        setFetchAdvisors(item);
      });
  };

  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
    handleFetch();
    handleFetchTeam();
    handleFetchAdvisors();
    handleFetchTeamMember();
    handleFetchAdvisorMember();
  }, []);

  return (
    <Box
      style={{
        overflow: "hidden",
      }}
    >
      <ScrollTop />
      <Intro data={fetch} />
      <AboutSection data={fetch} />
      <Card data={fetch} />
      <TeamQuain data={fetchTeam} memberData={fetchTeamMember} />
      <AdvisorCard advisorData={fetchAdvisors} data={fetchAdvisorsMember} />
    </Box>
  );
};
