import React, { useState } from "react";
//Material ui
import { makeStyles } from "@material-ui/core";
import { TeamIntroTable } from "./TeamIntroTable";
import { AddTeamMember } from "./AddTeamMember";
import { AdvisorAddTeamMember } from "./AdvisorTeamMember";
import ManageTeamMembers from "./manageTeamMembers";
import ManageAdvisorMembers from "../AboutData/manageAdvisors";
import AddAdvisors from "../AboutData/addMembers";
const active = {
  backgroundColor: "#fff",
  border: "1px solid black",
  color: "#000",
  padding: "1%",
};

const unActive = {
  backgroundColor: "#168ACB",
  border: "1px solid #168ACB",
  color: "#fff",
  padding: "1%",
};

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
    textAlign: "center",
  },
  portfolioInputBox: {
    border: "2px solid red",
  },
}));

export const TeamQuain = ({ data, handleClickOpen, isMemberInstance }) => {
  const classes = useStyles();
  const [Show, setShow] = useState("data");
  return (
    <>
      <div
        style={{
          width: "60%",
          margin: "10px auto",
          display: "flex",
          justifyContent: "space-around",
          alignItemsL: "center",
        }}
      >
        <div
          className={classes.button}
          style={Show === "data" ? unActive : active}
        >
          <h1
            onClick={() => setShow("data")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Banner
          </h1>
        </div>

        <div
          className={classes.button}
          style={Show === "team" ? unActive : active}
        >
          <h1
            onClick={() => setShow("team")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            {isMemberInstance ? "Add Member" : "Add Advisor"}
          </h1>
        </div>
        <div
          className={classes.button}
          style={Show === "manage" ? unActive : active}
        >
          <h1
            onClick={() => setShow("manage")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Manage
          </h1>
        </div>
      </div>

      {Show === "data" ? (
        <>
          <TeamIntroTable data={data} handleClickOpens={handleClickOpen} />
        </>
      ) : (
        ""
      )}

      {Show === "team" ? (
        <>{isMemberInstance ? <AdvisorAddTeamMember /> : <AddAdvisors />}</>
      ) : (
        ""
      )}
      {Show === "manage" ? (
        <>
          {isMemberInstance ? <ManageTeamMembers /> : <ManageAdvisorMembers />}
        </>
      ) : (
        ""
      )}
    </>
  );
};
