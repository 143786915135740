import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { AddNews } from "./Addnewsandblog/AddNews";
import { AddBlog } from "./Addnewsandblog/AddBlog";
import { NewsTable } from "./NewsAndBlogTable/NewsTable";
import { BlogTable } from "./NewsAndBlogTable/BlogTable";
import { NewsUpdate } from "./UpDatePopUp/NewsUpdate";
//Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Firebase
import { storage, firestore } from "../../../../Firebase";

const active = {
  backgroundColor: "#fff",
  border: "1px solid black",
  color: "#000",
};

const unActive = {
  backgroundColor: "#168ACB",
  border: "1px solid #168ACB",
  color: "#fff",
};

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
    textAlign: "center",
  },
  portfolioInputBox: {
    border: "2px solid red",
  },
  button: {
    width: "8rem",
    border: "1px solid black",
    padding: "1%",
    "&:hover": {
      backgroundColor: "#168ACB",
      border: "1px solid #168ACB",
      color: "#fff",
    },
  },
}));

export const NewsAndBlog = () => {
  const classes = useStyles();
  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));
  const [fetchNews, setFetchNews] = useState("");
  const [fetchBlog, setFetchBlog] = useState("");
  const [openNews, setOpenNews] = useState(false);
  const [openBlog, setOpenBlog] = useState(false);
  const [NewsDetails, setNewsDetails] = useState("");
  const [newsDocumentID, setNewsDocumentID] = useState("");
  const [BlogDetails, setBlogDetails] = useState("");
  const [blogDocumentID, setBlogDocumentID] = useState("");
  const [Show, setShow] = useState("news");

  const handleSubmitNews = (state) => {
    const { dateandtime, title, category, link, image } = state;
    const uploadTask = storage.ref(`newsImage/${image.name}`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progressStatus = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        progressStatus === 0
          ? toast.info("pending")
          : toast.info(
              "Image is Uploading please wait for product data upload"
            );
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("newsImage")
          .child(image.name)
          .getDownloadURL()
          .then((imageURL) => {
            firestore
              .collection("news")
              .doc()
              .set({
                dateandtime,
                title,
                category,
                link,
                image: imageURL,
              })
              .then(() => {
                toast.success("Successful.");
              })
              .catch((err) => {
                console.log(err);
                toast.error(err);
              });
          });
      }
    );
  };

  const handleSubmitBlog = (state) => {
    const { dateandtime, title, category, link, image } = state;
    const uploadTask = storage.ref(`blogImage/${image.name}`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progressStatus = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        progressStatus === 0
          ? toast.info("pending")
          : toast.info(
              "Image is Uploading please wait for product data upload"
            );
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("blogImage")
          .child(image.name)
          .getDownloadURL()
          .then((imageURL) => {
            firestore
              .collection("blog")
              .doc()
              .set({
                dateandtime,
                title,
                category,
                link,
                image: imageURL,
              })
              .then(() => {
                toast.success("Successful.");
              })
              .catch((err) => {
                console.log(err);
                toast.error(err);
              });
          });
      }
    );
  };

  const handleFetchNews = () => {
    firestore
      .collection("news")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), documentID: doc.id });
        });
        setFetchNews(item);
      });
  };

  const handleFetchBlog = () => {
    firestore
      .collection("blog")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), documentID: doc.id });
        });
        setFetchBlog(item);
      });
  };

  const handleDeleteNews = (documentID) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("news")
        .doc(documentID)
        .delete()
        .then((success) => {
          toast.success("Tile Deleted");
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleDeleteBlog = (documentID) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("blog")
        .doc(documentID)
        .delete()
        .then((success) => {
          toast.success("Tile Deleted");
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleNewsUpdate = (state) => {
    const { dateandtime, title, category, link, image } = state;
    console.log(state);
    return new Promise((resolve, reject) => {
      handleCloseNews();

      const uploadTask = storage.ref(`newsImage/${image.name}`).put(image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressStatus = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          progressStatus === 0
            ? toast.info("pending")
            : toast.info(
                "Image is Uploading please wait for product data upload"
              );
        },
        (error) => {
          toast.error(error);
        },
        () => {
          storage
            .ref("newsImage")
            .child(image.name)
            .getDownloadURL()
            .then((imageURL) => {
              firestore
                .collection("news")
                .doc(newsDocumentID)
                .update({
                  dateandtime,
                  title,
                  category,
                  link,
                  image: imageURL,
                })
                .then(() => {
                  resolve();
                  toast.success("Tile Update");
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(err);
                  reject();
                });
            });
        }
      );
    });
  };

  const handleBlogUpdate = (state) => {
    const { dateandtime, title, category, link, image } = state;
    console.log(state);
    return new Promise((resolve, reject) => {
      handleCloseBlog();

      const uploadTask = storage.ref(`blogImage/${image.name}`).put(image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressStatus = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          progressStatus === 0
            ? toast.info("pending")
            : toast.info(
                "Image is Uploading please wait for product data upload"
              );
        },
        (error) => {
          toast.error(error);
        },
        () => {
          storage
            .ref("blogImage")
            .child(image.name)
            .getDownloadURL()
            .then((imageURL) => {
              firestore
                .collection("blog")
                .doc(blogDocumentID)
                .update({
                  dateandtime,
                  title,
                  category,
                  link,
                  image: imageURL,
                })
                .then(() => {
                  resolve();
                  toast.success("Tile Update");
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(err);
                  reject();
                });
            });
        }
      );
    });
  };

  const handleClickOpenNews = (id) => {
    setOpenNews(true);
    setNewsDocumentID(id);
    firestore
      .collection("news")
      .doc(id)
      .get()
      .then((snapshot) => setNewsDetails(snapshot.data()));
  };

  const handleClickOpenBlog = (id) => {
    setOpenBlog(true);
    setBlogDocumentID(id);
    firestore
      .collection("blog")
      .doc(id)
      .get()
      .then((snapshot) => setBlogDetails(snapshot.data()));
  };

  const handleCloseNews = () => {
    setOpenNews(false);
  };

  const handleCloseBlog = () => {
    setOpenBlog(false);
  };

  useEffect(() => {
    handleFetchNews();
    handleFetchBlog();
  }, []);

  return (
    <div>
      <h1 className={classes.Heading}>News and Blog Admin Dashboard</h1>

      <div
        style={{
          width: "30%",
          margin: "2% auto",
          display: "flex",
          justifyContent: "space-around",
          alignItemsL: "center",
        }}
      >
        <div
          className={classes.button}
          style={Show === "news" ? unActive : active}
        >
          <h1
            onClick={() => setShow("news")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            News
          </h1>
        </div>
        <div
          className={classes.button}
          style={Show === "blog" ? unActive : active}
        >
          <h1
            onClick={() => setShow("blog")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Blog
          </h1>
        </div>
      </div>

      {Show === "news" ? (
        <>
          <AddNews handleSubmitNews={handleSubmitNews} />
          <NewsTable
            data={fetchNews}
            handleDeleteNews={handleDeleteNews}
            handleClickOpen={handleClickOpenNews}
          />
          <NewsUpdate
            open={openNews}
            handleClose={handleCloseNews}
            NewsDetails={NewsDetails}
            handleUpdate={handleNewsUpdate}
          />
        </>
      ) : (
        ""
      )}

      {Show === "blog" ? (
        <>
          <AddBlog handleSubmitBlog={handleSubmitBlog} />
          <BlogTable
            data={fetchBlog}
            handleDeleteBlog={handleDeleteBlog}
            handleClickOpen={handleClickOpenBlog}
          />
          <NewsUpdate
            open={openBlog}
            handleClose={handleCloseBlog}
            NewsDetails={BlogDetails}
            handleUpdate={handleBlogUpdate}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
