import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  CardBoxRadius: {
    width: "90%",
    margin: "0 auto",
    marginTop: "5%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  CardBox: {
    width: "30%",
    height: "42rem",
    margin: "5% 0%",
    padding: "2%",
    borderRadius: "2%",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      paddingLeft: "8%",
      // paddingTop: '5%',
      height: "100%",
    },
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  wave: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headStyle: {
    font: "normal normal bold 1.8vw Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
    },
  },
  para2Style: {
    font: "normal normal 300 1.5vw Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
    },
  },
}));

export const Card = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box className={classes.CardBoxRadius}>
      <Box
        className={classes.CardBox}
        style={{ backgroundColor: "#333333" }}
        data-aos="fade-up"
      >
        <img
          src={"./Images/Plateform/circle1.png"}
          className={classes.image}
          alt="id"
          data-aos="fade-up"
        />
        <img
          style={{ marginLeft: "42%", marginBottom: "5%" }}
          className={classes.wave}
          src={"./Images/Plateform/wave.png"}
          alt="id"
          data-aos="fade-up"
        />

        <br />
        <h1 className={classes.headStyle} data-aos="fade-up">
          {data ? data[1].state : "loading"} <br />{" "}
          {data ? data[12].state : "loading"}
        </h1>
        <br />

        <ul>
          <li data-aos="fade-up">
            <h1 className={classes.para2Style}>
              {data ? data[8].state : "loading"}
            </h1>
          </li>
          <li data-aos="fade-up">
            <h1 className={classes.para2Style}>
              {data ? data[42].state : "loading"}
            </h1>
          </li>
        </ul>
      </Box>

      <Box
        className={classes.CardBox}
        style={{ backgroundColor: "#168ACB" }}
        data-aos="fade-up"
      >
        <img
          src={"./Images/Plateform/circle2.png"}
          className={classes.image}
          alt="id"
          data-aos="fade-up"
        />
        <img
          style={{ marginLeft: "42%", marginBottom: "5%" }}
          className={classes.wave}
          src={"./Images/Plateform/wave.png"}
          alt="id"
          data-aos="fade-up"
        />

        <br />
        <h1 className={classes.headStyle} data-aos="fade-up">
          {data ? data[28].state : "loading"} <br />{" "}
          {data ? data[30].state : "loading"}
        </h1>
        <br />

        <ul>
          <li data-aos="fade-up">
            <h1 className={classes.para2Style}>
              {data ? data[6].state : "loading"}
            </h1>
          </li>
        </ul>
      </Box>

      <Box
        className={classes.CardBox}
        style={{ backgroundColor: "#333333" }}
        data-aos="fade-up"
      >
        <img
          src={"./Images/Plateform/circle3.png"}
          className={classes.image}
          alt="id"
          data-aos="fade-up"
        />
        <img
          style={{ marginLeft: "42%", marginBottom: "5%" }}
          className={classes.wave}
          src={"./Images/Plateform/wave.png"}
          alt="id"
          data-aos="fade-up"
        />

        <br />
        <h1 className={classes.headStyle} data-aos="fade-up">
          {data ? data[26].state : "loading"} <br />{" "}
          {data ? data[21].state : "loading"}
        </h1>
        <br />

        <ul>
          <li data-aos="fade-up">
            <h1 className={classes.para2Style}>
              {data ? data[25].state : "loading"}
            </h1>
          </li>
          <br />
          <li data-aos="fade-up">
            <h1 className={classes.para2Style}>
              {data ? data[31].state : "loading"}
            </h1>
          </li>
        </ul>
      </Box>
    </Box>
  );
};
