import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Menu,
} from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
//Firestore
import { firestore } from "../../../../Firebase";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: "0px 10px",
  },
  main: {
    width: "95%",
    textAlign: "center",
    margin: "0% auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "10%",
    },
  },
  buttonBox: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  button: {
    width: "134px",
    padding: "0.8% 0%",
    borderRadius: "6px",
    border: "none",
    color: "#fff",
    fontFamily: "Nunito-Regular",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    marginRight: "2%",
    marginBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "5%",
      width: "5rem",
    },
  },
  grid: {
    marginTop: "5%",
    marginBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      margin: "0% auto",
    },
  },
  allBox: {
    border: "1px solid black",
    boxShadow: "0px 3px 6px #00000029",
    height: "230px",
    borderRadius: "10px",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  companyName: {
    font: " normal normal normal 1rem Montserrat",
    color: "#000",
  },
  set: {
    color: "#fff",
    font: " normal normal normal 1rem Montserrat",
    padding: "2px 15px",
    backgroundColor: "#0CB14B",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: "1px 5px",
      fontSize: ".8rem",
    },
  },
  Sector: {
    font: "normal normal 300 1rem Montserrat",
    color: "#000",
  },
  keyBox: {
    marginTop: "12%",
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    margin: "30px 10px",
    textAlign: "center",
  },
  HeadCenter: {
    fontFamily: "Nunito-Regular",
    fontSize: "24px",
    color: "#168ACB",
    textAlign: "center",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
  },
  filterbtn: {
    border: "1px solid black",
    backgroundColor: "#fff",
    color: "#000",
    float: "right",
  },
  filter_sub_header_container: {
    borderBottom: "2px dashed #D7D7D7",
    padding: "10px",
  },
  "@media (max-width: 1367px)": {
    main: {
      paddingBottom: "20px",
    },
  },
}));

const activebackground = {
  border: "1px solid black",
  backgroundColor: "#fff",
  color: "#000",
};

const unActivebackgroundColor = {
  border: "none",
  backgroundImage:
    "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
  color: "#fff",
};

export const ThreeButton = ({ data }) => {
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [filterData, setFilterData] = useState();
  const [active, setActive] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [sectors, setSectors] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {

    if (sectors.includes(item)) {
      setSectors((prevSectors) =>
        prevSectors.filter((value) => value !== item)
      );
    } else {
      setSectors((prevSectors) => [...prevSectors, item]);
    }
    setCheckedItems({ ...checkedItems, [item]: !checkedItems[item] });
  };

  const handleFetch = () => {
    firestore
      .collection("portolioPageCardsData")
      .orderBy("companyName", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc, index) => {
          item.push(doc.data());
        });
        let data = [];
        for (let i = 0; i < item.length; i++) {
          if (!item[i].status) {
            item[i].status = "Operational";
          }
          data.push(item[i]);
        }

        setState(item);
        setFilterData(item);
      });
  };
  const filterItem = (result) => {
    setActive(result);
    const response = state.filter((item) => {
      return item.stage === result;
    });

    setCheckedItems({})
    setFilterData(response);
  };
  const activeAll = () => {

    setCheckedItems({})
    setActive("all");
    setFilterData(state);
  };
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1200,
    });
    handleFetch();
  }, []);
  useEffect(() => {

    if (sectors.length > 0) {
      const filteredData = state.filter((item) => {
        for (const sector of sectors) {
          if (item.sector.toLowerCase().includes(sector.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
      if (active === "all") {
        setFilterData(filteredData)
      }
      else {
        let stageFilter = filteredData.filter(list => list.stage === active)
        setFilterData(stageFilter)
      }
    } else {
      if (active === "all") {
        setFilterData(state)
      }
      else {
        let stageFilter = state.filter(list => list.stage === active)
        setFilterData(stageFilter)
      }

    }
  }, [sectors]);

  return (
    <Box className={classes.main}>
      <p className={classes.mainHeader}>
        <span className={`position-relative`}>
          Our {data ? data[0].state : "loading"}
        </span>
        <div className="highlighter"></div>
      </p>
      <h1 className={classes.HeadCenter} data-aos="fade-up">
        {data ? data[1].state : "loading"}
      </h1>
      <Box className={classes.buttonBox}>
        <button
          style={active === "all" ? unActivebackgroundColor : activebackground}
          className={classes.button}
          data-aos="fade-up"
          data-aos-delay="300"
          onClick={activeAll}
        >
          All
        </button>
        <button
          style={
            active === "Early" ? unActivebackgroundColor : activebackground
          }
          className={classes.button}
          data-aos="fade-up"
          data-aos-delay="400"
          onClick={() => filterItem("Early")}
        >
          Early
        </button>
        <button
          style={
            active === "Growth" ? unActivebackgroundColor : activebackground
          }
          className={classes.button}
          data-aos="fade-up"
          data-aos-delay="500"
          onClick={() => filterItem("Growth")}
        >
          Growth
        </button>
        <button
          className={`${classes.button} ${classes.filterbtn}`}
          data-aos="fade-up"
          data-aos-delay="500"
          onClick={handleClick}
        >
          <span style={{ marginRight: "5px" }}>Filter By</span>
          <img
            src="/Images/Portfolio/filter.png"
            style={{ verticalAlign: "middle" }}
          />
        </button>
        <Menu
          anchorEl={anchorEl}
          style={{
            width: "180px"
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={classes.filter_sub_header_container}>
            <p className={classes.filter_sub_header}>Sectors</p>
          </div>
          <MenuItem className={classes.menuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedItems.BioTech ? true : false}
                  onChange={() => handleMenuItemClick("BioTech")}
                />
              }
              label="BioTech"
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedItems.Fintech ? true : false}
                  onChange={() => handleMenuItemClick("Fintech")}
                />
              }
              label="Fintech"
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedItems.Finance ? true : false}
                  onChange={() => handleMenuItemClick("Finance")}
                />
              }
              label="Finance"
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedItems.Logistics ? true : false}
                  onChange={() => handleMenuItemClick("Logistics")}
                />
              }
              label="Logistics"
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedItems.Pharma ? true : false}
                  onChange={() => handleMenuItemClick("Pharma")}
                />
              }
              label="Pharma"
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedItems.Software ? true : false}
                  onChange={() => handleMenuItemClick("Software")}
                />
              }
              label="Software"
            />
          </MenuItem>
        </Menu>
      </Box>
      <Grid container xs={12} spacing={4} className={classes.grid}>
        {filterData
          ? filterData.map((item, index) => {
            const { logo, companyName, sector, stage, link, status } = item;
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                data-aos="fade-up"
                data-aos-delay="600"
                key={index}
              >
                <a href={link} target="_blank">
                  <Box
                    title={
                      status.toLowerCase() === "operational"
                        ? "Operational"
                        : status.toLowerCase() === "shutdown"
                          ? "Shut Down"
                          : status.toLowerCase() === "exited"
                            ? "Exited"
                            : ""
                    }
                    className={
                      status.toLowerCase() === "operational"
                        ? "bg-success " + classes.allBox
                        : status.toLowerCase() === "shutdown"
                          ? "bg-danger " + classes.allBox
                          : status.toLowerCase() === "exited"
                            ? "bg-secondary " + classes.allBox
                            : classes.allBox
                    }
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "75px"
                      }}
                    >
                      <Box>
                        <img
                          className={classes.image}
                          src={logo}
                          alt={companyName}
                        />
                        <h1 className={classes.companyName}>{companyName}</h1>
                      </Box>
                      <Box>
                        <h1 className={classes.set}>{stage}</h1>
                      </Box>
                    </Box>
                    <Box className={classes.keyBox}>
                      <h1 className={classes.companyName}>Sectors</h1>
                      <h1 className={classes.Sector}>{sector}</h1>
                    </Box>
                  </Box>
                </a>
              </Grid>
            );
          })
          : "loading"}
      </Grid>
    </Box>
  );
};
