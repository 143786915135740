import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
// Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  backImg: {
    width: "100%",
    height: "100%",
    backgroundPosition: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20px 0",
      fontSize: "1rem",
      lineHeight: "1.5",
      height: "300px",
    },
  },
  overlay: {
    position: "absolute",
    top: "54%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 70px)",
    height: "calc(100% - 150px)",
    backgroundColor: "#FFFDFD",
    borderRadius: "20px",
    opacity: 0.9,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      top: "58%",
      flexDirection: "column",
    },
  },
  PositionRelative: {
    position: "relative",
  },
  leftContent: {
    flex: 1,
    padding: "20px",
  },
  rightContent: {
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  leftContentText: {
    color: "#168ACB",
    fontSize: "106px",
    fontFamily: "Nunito-Regular",
    fontWeight: "600",
    marginTop: "115px",
    marginLeft: "1%",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  rightContentImg: {
    width: "100%",
    height: "auto",
    marginTop: "15%",
    marginLeft: "-10%",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "100%",
      marginTop: "-10px",
    },
  },
  '@media (max-width: 1367px)': {
    rightContentImg: {
      marginTop: "8%",
    },
    leftContentText: {
      fontSize: "92px"
    }
  },
}));

export const Intro = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box className={classes.PositionRelative} data-aos="fade-up">
      <img src={"/Images/Bg/BG14.png"} className={classes.backImg} />
      <div className={classes.overlay}>
        <div className={classes.leftContent} data-aos="fade-up">
          <p className={classes.leftContentText}>
            {" "}
            {data ? data[0].state : "loading"}
          </p>
        </div>
        <div className={classes.rightContent} data-aos="fade-right">
          <img
            className={classes.rightContentImg}
            src="./Images/Social/social-banner.png"
            alt=""
          />
        </div>
      </div>
    </Box>
  );
};
