import React, { Fragment, useEffect, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import emailjs from '@emailjs/browser';
import aos from "aos";
import { toast } from "react-toastify";
import "aos/dist/aos.css";
import { AddressLocation } from "./AddressLocation";
import ScrollToTop from "react-scroll-to-top";
import { firestore } from "../../../Firebase";
import { Opacity } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  PositionRelative: {
    display: "flex",
    position: "relative",
    marginTop: "150px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rightContent: {
    flex: 1,
    padding: "20px",
    marginLeft: "-10%",
  },
  leftContent: {
    flex: 1,
    height: "500px",
    width: "500px",
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "80px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  rightContentText: {
    color: "#168ACB",
    fontSize: "40px",
    fontFamily: "Nunito-Regular",
    fontWeight: "600",
    marginLeft: "50px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
      marginLeft: "0",
    },
  },
  rightContentImg: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },

  rightContent_input: {
    width: "80%",
    borderRadius: "8px",
    padding: "15px",
    border: "1px solid #E0E0E0",
    margin: "15px 10px",
    fontSize: "14px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
  },
  errorText: {
    color: "red",
    marginLeft: "15px",
    fontSize: "12px",
    marginTop: "-11px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
  },
  button: {
    width: "80%",
    borderRadius: "6px",
    padding: "12px 22px 13px 21px",
    background:
      "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
    border: "none",
    color: "#fff",
    fontFamily: "Nunito-Regular",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    marginBottom: "5%",
    marginTop: "2%",
    marginLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

}));

export const Contactus = () => {
  const classes = useStyles();
  const [errors, setErrors] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const form = useRef();
  const [getintouch, setGetintouch] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [fetch, setFetch] = useState("");
  const handleFetch = () => {
    firestore.collection("contactUs").onSnapshot((querySnapshot) => {
      const itemdummy = [];
      querySnapshot.forEach((doc) => {
        itemdummy.push(doc.data())
      });

      setFetch(itemdummy);
    });
  };
  let name, value;

  const onChangeGet = (event) => {
    name = event.target.name;
    value = event.target.value;
    setGetintouch({ ...getintouch, [name]: value });
  };

  const handleSubmit = (e) => {
    setDisabled(true)
    if (getintouch.name == "" || getintouch.phoneNumber == "") {
      setDisabled(false)
      setErrors(true);
    } else {
      setErrors(false);

      emailjs
        .sendForm(
          "service_quain",
          "template_xgy50ex",
          form.current,
          "j3NJKsVVsOgyFQQJh"
        )
        .then((res) => {
          setDisabled(false)
          if (res.status === 200) {
            for (let i = 0; i < form.current.length; i++) {
              form.current[i].value = ""
            }
            setGetintouch({
              name: "",
              email: "",
              message: "",
              phoneNumber: "",
            });
            toast.success("Email sent successfully", { position: "top-center", theme: "colored", autoClose: 3000 });

          } else {
            toast.error("something is wrong", { position: "top-center", theme: "colored", autoClose: 3000 });
          }
        })
        .catch((error) => {
          setDisabled(false)
          console.log(error);
        })
    }
  };

  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
    handleFetch();
  }, []);

  return (
    <Fragment>
      <ScrollToTop />
      <Box className={classes.PositionRelative} data-aos="fade-up">
        <div className={classes.leftContent}>
          <img
            className={classes.rightContentImg}
            src="./Images/Contact/contact_us.png"
            alt=""
          />
        </div>
        <div className={classes.rightContent} data-aos="fade-right">
          <p className={classes.rightContentText}>Let us connect together</p>
          <form ref={form}>
            <div>
              <input
                required
                name="name"
                value={getintouch.name}
                onChange={onChangeGet}
                data-aos="fade-up"
                type="text"
                placeholder="Name *"
                className={classes.rightContent_input}
              />
              {errors == true && getintouch.name == "" && (
                <p className={classes.errorText}>Name is Required</p>
              )}
            </div>
            <div>
              <input
                required
                placeholder="Email"
                type="email"
                name="email"
                value={getintouch.email}
                onChange={onChangeGet}
                data-aos="fade-up"
                className={classes.rightContent_input}
              />
            </div>
            <div>
              <input
                required
                type="text"
                name="phoneNumber"
                value={getintouch.phoneNumber}
                onChange={onChangeGet}
                data-aos="fade-up"
                placeholder="Phone number *"
                className={classes.rightContent_input}
              />
              {errors == true && getintouch.phoneNumber == "" && (
                <p className={classes.errorText}>Phone Number is Required</p>
              )}
            </div>
            <div>
              <textarea
                required
                name="message"
                value={getintouch.message}
                onChange={onChangeGet}
                data-aos="fade-up"
                placeholder="Message"
                style={{
                  resize: "none",
                }}
                type="text"
                className={classes.rightContent_input}
              />
            </div>
            <div>
              <button
                onClick={() => {
                  handleSubmit();
                }}
                type="button"
                data-aos="fade-up"
                style={disabled ? { pointerEvents: "none", opacity: 0.5 } : { opacity: 1 }}
                className={classes.button}
              >
                SEND MESSAGE
              </button>
            </div>
          </form>
        </div>
      </Box>
      <AddressLocation data={fetch} />
    </Fragment>
  );
};
