import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCSDfQx5jcOxJtXB61FYDMzSAsNgQkHHsw",
  authDomain: "quaininvestment.firebaseapp.com",
  projectId: "quaininvestment",
  storageBucket: "quaininvestment.appspot.com",
  messagingSenderId: "449404806497",
  appId: "1:449404806497:web:3654947e289cb76bf49120",
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = firebase.storage();
export const firestore = firebase.firestore();
