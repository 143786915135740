import React from "react";
//Material Ui
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";

export const TeamIntroTable = ({ data, handleClickOpens }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sr No</TableCell>
            <TableCell>Context</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ? data.map((item, index) => {
                const { state, documentID } = item;
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{state}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickOpens(documentID)}
                    >
                      Edit
                    </TableCell>
                  </TableRow>
                );
              })
            : "loading"}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
