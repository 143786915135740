import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { firestore } from "../../../../Firebase";
import { AdminCommonTable } from "../../../Molecule";
import { AboutTable } from "./AboutData/AboutTable";
import { UpdateHomePop } from "./AboutData/UpdateTilesPopup";
import { TeamQuain } from "./Team";

const active = {
  backgroundColor: "#fff",
  border: "1px solid black",
  color: "#000",
  padding: "1%",
};

const unActive = {
  backgroundColor: "#168ACB",
  border: "1px solid #168ACB",
  color: "#fff",
  padding: "1%",
};

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
    textAlign: "center",
  },
  portfolioInputBox: {
    border: "2px solid red",
  },
}));

export const AboutDashboard = () => {
  const classes = useStyles();

  const [Show, setShow] = useState("data");

  const [fetch, setFetch] = useState("");

  const [fetchTeamQuain, setFetchTeamQuain] = useState("");

  const [fetchTeamAdvisors, setFetchTeamAdvisors] = useState("");

  const [open, setOpen] = useState(false);

  const [openTeam, setOpenTeam] = useState(false);

  const [openAdvisors, setOpenAdvisors] = useState(false);

  const [HomeDetails, setHomeDetails] = useState("");

  const [TeamDetails, setTeamDetails] = useState("");

  const [AdvisorDetails, setAdvisorDetails] = useState("");

  const [documentID, setDocumentID] = useState("");

  const [documentIDTeam, setDocumentIDTeam] = useState("");

  const [documentIDAdvisor, setDocumentIDAdvisor] = useState("");

  const handleClickOpen = (id) => {

    setOpen(true);
    setDocumentID(id);
    firestore
      .collection("aboutPage")
      .doc(id)
      .get()
      .then((snapshot) => setHomeDetails(snapshot.data()));
  };

  const handleClickOpenTeam = (id) => {
    setOpenTeam(true);
    setDocumentIDTeam(id);
    firestore
      .collection("teamMemberQuain")
      .doc(id)
      .get()
      .then((snapshot) => setTeamDetails(snapshot.data()));
  };

  const handleClickOpenAdvisors = (id) => {
    setOpenAdvisors(true);
    setDocumentIDAdvisor(id);
    firestore
      .collection("aboutPageTeamAdvisors")
      .doc(id)
      .get()
      .then((snapshot) => setAdvisorDetails(snapshot.data()));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseTeam = () => {
    setOpenTeam(false);
  };

  const handleCloseAdvisors = () => {
    setOpenAdvisors(false);
  };

  const handleUpdate = (state) => {

    return new Promise((resolve, reject) => {
      firestore
        .collection("aboutPage")
        .doc(documentID)
        .update({ state })
        .then(() => {

          resolve();
          handleClose();
          toast.success("Update");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleUpdateTeam = (state) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("teamMemberQuain")
        .doc(documentIDTeam)
        .update({ state })
        .then(() => {
          resolve();
          handleCloseTeam();
          toast.success("Update");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleUpdateAdvisors = (state) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("aboutPageTeamAdvisors")
        .doc(documentIDAdvisor)
        .update({ state })
        .then(() => {
          resolve();
          handleCloseAdvisors();
          toast.success("Update");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFetch = () => {
    firestore
      .collection("aboutPage")
      .orderBy("state", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), documentID: doc.id });
        });
        setFetch(item);
      });
  };

  const handleFetchTeamQuain = () => {
    firestore.collection("teamMemberQuain").onSnapshot((querySnapshot) => {
      const item = [];
      querySnapshot.forEach((doc) => {
        item.push({ ...doc.data(), documentID: doc.id });
      });
      setFetchTeamQuain(item);
    });
  };

  const handleFetchTeamAdvisors = () => {
    firestore
      .collection("aboutPageTeamAdvisors")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), documentID: doc.id });
        });
        setFetchTeamAdvisors(item);
      });
  };

  useEffect(() => {
    handleFetch();
    handleFetchTeamQuain();
    handleFetchTeamAdvisors();
  }, []);

  return (
    <div>
      <h1 className={classes.Heading}>About Quain</h1>

      <div
        style={{
          width: "50%",
          margin: "2% auto",
          display: "flex",
          justifyContent: "space-around",
          alignItemsL: "center",
        }}
      >
        <div
          className={classes.button}
          style={Show === "data" ? unActive : active}
        >
          <h1
            onClick={() => setShow("data")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Data
          </h1>
        </div>

        <div
          className={classes.button}
          style={Show === "team" ? unActive : active}
        >
          <h1
            onClick={() => setShow("team")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Team Quain
          </h1>
        </div>

        <div
          className={classes.button}
          style={Show === "advisors" ? unActive : active}
        >
          <h1
            onClick={() => setShow("advisors")}
            className={classes.Heading}
            style={{ cursor: "pointer" }}
          >
            Advisors
          </h1>
        </div>
      </div>

      {Show === "data" ? (
        <>
          <AdminCommonTable Data={fetch} handleClickOpen={handleClickOpen} />
          <UpdateHomePop
            open={open}
            handleClose={handleClose}
            HomeDetails={HomeDetails}
            handleUpdate={handleUpdate}
          />
        </>
      ) : (
        ""
      )}
      {Show === "team" ? (
        <>
          <TeamQuain
            isMemberInstance={true}
            data={fetchTeamQuain}
            handleClickOpen={handleClickOpenTeam}
          />
          <UpdateHomePop
            open={openTeam}
            handleClose={handleCloseTeam}
            HomeDetails={TeamDetails}
            handleUpdate={handleUpdateTeam}
          />
        </>
      ) : (
        ""
      )}
      {Show === "advisors" ? (
        <>
          <TeamQuain
            isMemberInstance={false}
            data={fetchTeamAdvisors}
            handleClickOpen={handleClickOpenAdvisors}
          />
          <UpdateHomePop
            open={openAdvisors}
            handleClose={handleCloseAdvisors}
            HomeDetails={AdvisorDetails}
            handleUpdate={handleUpdateAdvisors}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
