import React, { useEffect, useState } from "react";
import { Intro } from "./Intro";
import { QuainBrain } from "./QuainBrain";
import { Box } from "@material-ui/core";
import { ScrollTop } from "../../Molecule/ScrollToTop";
import { firestore } from "../../../Firebase";

export const SocialResponsibility = () => {
  const [fetch, setFetch] = useState("");

  const handleFetch = () => {
    firestore
      .collection("socialResponsibilityPageContent")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push(doc.data());
        });
        setFetch(item);
      });
  };
  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
    handleFetch();
  }, [1]);
  return (
    <Box>
      <ScrollTop />
      <Intro data={fetch} />
      <QuainBrain data={fetch} />
    </Box>
  );
};
