import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
//Material ui
import { Menu, Close } from "@material-ui/icons";
//Logo
import Logo from "../../Assets/Logo.png";
import Aos from "aos";

export const Navbar = () => {
  const location = useLocation();
  const refresher = ()=>{
    Aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }
  const [state, setState] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="header_container">
      <div className="header">
        <div className="logoBox">
          <NavLink onClick={()=>{
            if(location.pathname=="/"){
              window.location.reload();
            }else{
              navigate("/");
            }
          }} className="headerText" to="/">
            <img className="logo" src={Logo} alt="LOGO" />
            <span className="logoText">Quain Investments</span>
          </NavLink>
        </div>

        <div className="nav_menu">
          <ul className="nav_menu_ul">
            <li className="nav_menu_li">
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending
                    ? "nav_menu_li"
                    : isActive
                    ? "nav_menu_li_active"
                    : ""
                }
                exact
                to="/about"
              >
                About Quain
              </NavLink>
            </li>
            <li className="nav_menu_li">
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending
                    ? "nav_menu_li"
                    : isActive
                    ? "nav_menu_li_active"
                    : ""
                }
                exact
                to="/about-platform"
              >
                Platform
              </NavLink>
            </li>
            <li className="nav_menu_li">
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending
                    ? "nav_menu_li"
                    : isActive
                    ? "nav_menu_li_active"
                    : ""
                }
                exact
                to="/portfolio"
              >
                Portfolio
              </NavLink>
            </li>
            <li className="nav_menu_li">
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending
                    ? "nav_menu_li"
                    : isActive
                    ? "nav_menu_li_active"
                    : ""
                }
                exact
                to="/social-responsibility"
              >
                Social Responsibility
              </NavLink>
            </li>
            <li className="nav_menu_li">
              <NavLink exact to="/contact-us">
                <button className="button">Contact us</button>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="Show_Menu">
          {state ? (
            <Close
              style={{ cursor: "pointer" }}
              fontSize="large"
              onClick={() => {
                state === false ? setState(true) : setState(false);
              }}
            />
          ) : (
            <Menu
              style={{ cursor: "pointer" }}
              fontSize="large"
              onClick={() => {
                state === false ? setState(true) : setState(false);
              }}
            />
          )}
        </div>
      </div>

      {state ? (
        <div className="MENU">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav_menu_li" : isActive ? "nav_menu_li_active" : ""
            }
            exact
            to="/about"
            onClick={() => setState(false)}
          >
            About Quain
          </NavLink>
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav_menu_li" : isActive ? "nav_menu_li_active" : ""
            }
            exact
            to="/about-platform"
            onClick={() => setState(false)}
          >
            Platform
          </NavLink>
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav_menu_li" : isActive ? "nav_menu_li_active" : ""
            }
            exact
            to="/portfolio"
            onClick={() => setState(false)}
          >
            Portfolio
          </NavLink>
          {/* <NavLink
                            className="nav_menu_li"
                            activeClassName='nav_menu_li_active'
                            exact
                            to='/newsandevent'
                           
                        
                            onClick={() => setState(false)}
                        >
                            News and Events
                        </NavLink> */}
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav_menu_li" : isActive ? "nav_menu_li_active" : ""
            }
            exact
            to="/social-responsibility"
            onClick={() => setState(false)}
          >
            Social Responsibility
          </NavLink>
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav_menu_li" : isActive ? "nav_menu_li_active" : ""
            }
            exact
            to="/contact-us"
            onClick={() => setState(false)}
          >
            <button className="button">Contact us</button>
          </NavLink>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
