import React, { useEffect, useState } from "react";
//Material UI
import { Box } from "@material-ui/core";
import { Intro } from "./Intro";
import { ThreeButton } from "./ThreeButton";
import { ScrollTop } from "../../Molecule/ScrollToTop";
import { firestore } from "../../../Firebase";

export const Portfolio = () => {
  const [fetch, setFetch] = useState("");

  const handleFetch = () => {
    firestore.collection("portfolioPageContent").onSnapshot((querySnapshot) => {
      const item = [];
      querySnapshot.forEach((doc) => {
        item.push(doc.data());
      });
      setFetch(item);
    });
  };


  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
  }, [1]);

  useEffect(() => {
    handleFetch();
  }, [])
  return (
    <Box>
      <ScrollTop />
      <Intro />
      <ThreeButton data={fetch} />
    </Box>
  );
};
