import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
//Logo
import Logo from "../../Assets/Logo.png";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "25rem",
    marginTop: "-4px",
    padding: "8% 3%",
    background: "#172432",
    display: "flex",
    color: "#fff",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      flexDirection: "column",
      marginTop: "-1%",
    },
  },
  FooterBox1: {
    width: "25%",
    height: "100%",
    padding: "2%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footerLogo: {
    width: "5rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "3rem",
      marginTop: "12%",
    },
  },
  footerContent: {
    color: "#ACB5C1",
    font: "normal normal 400 14px/44px Montserrat",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  FooterBox2: {
    width: "65%",
    height: "100%",
    margin: "5% 0%",
    padding: "1%",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  footerUl: {
    fontFamily: "Nunito-Regular",
    fontSize: "24px",
    listStyle: "none",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 1rem/30px Montserrat",
    },
  },
  UlHead: {
    [theme.breakpoints.down("sm")]: {
      font: "normal normal bold 19px/60px Montserrat",
    },
  },
  FooterBox3: {
    width: "25%",
    height: "100%",
    padding: "2%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footerSocial: {
    display: "flex",
    justifyContent: "space-evenly",
    listStyle: "none",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  footerLink: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  footerHide: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      padding: "2%",
    },
  },
  hover: {
    cursor: "pointer",
    margin: "25px 0px",
  },
  social_icons: {
    cursor: "pointer",
    margin: "-10px 10px",
  },
  logoBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  '@media (max-width: 1367px)': {
    root: {
      margin: "2% auto",
    },
    main:{
      marginTop: "-41px",
    }
  }
}));

export const Footer = () => {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();

  return (
    <>
      <Box className={classes.main}>
        <div className={classes.logoBox}>
          <NavLink onClick={()=>{
            if(location.pathname=="/"){
              window.location.reload();
            }else{
              history("/");
            }
          }} className="headerText" to="/">
            <img className="logo" src={Logo} alt="LOGO" />
            <span
              className="logoText"
              style={{
                color: "#fff",
                fontFamily: "Nunito-Regular",
                fontSize: "32px",
                fontWeight: "300",
              }}
            >
              Quain Investments
            </span>
          </NavLink>
          <p className={classes.footerContent}>
            Copyright © 2024 QuainInvestments.
          </p>
        </div>

        <Box className={classes.FooterBox2}>
          <ul className={classes.footerUl}>
            <li className={classes.hover} onClick={() => history("/about")}>
              About Quain
            </li>

            <li
              className={classes.hover}
              onClick={() => history("/social-responsibility")}
            >
              Social Responsibility
            </li>
          </ul>

          <ul className={classes.footerUl}>
            <li
              className={classes.hover}
              onClick={() => history("/about-platform")}
            >
              Platform
            </li>
            <li className={classes.hover} onClick={() => history("/portfolio")}>
              Portfolio
            </li>
            <li
              className={classes.hover}
              onClick={() => history("/contact-us")}
            >
              Contact Us
            </li>
          </ul>
          <ul className={classes.footerUl}>
            <li className={classes.hover} style={{
              marginLeft:"11px",
              fontWeight:"bold"
            }}>Follow us on</li>
            <li className={classes.hover} 
            >
              <div>
                <img
                  className={classes.social_icons}
                  src="./Images/Icons/linkedin.png"
                  alt="linkedin"
                />
                <img
                  height={42}
                  width={42}
                  className={classes.social_icons}
                  src="./Images/Icons/twitter.png"
                  alt="twitter"
                />
                <img
                  className={classes.social_icons}
                  src="./Images/Icons/facebook.png"
                  alt="facebook"
                />
                <img
                  className={classes.social_icons}
                  src="./Images/Icons/instagram.png"
                  alt="instagram"
                />
              </div>
            </li>
          </ul>
        </Box>
      </Box>
    </>
  );
};
