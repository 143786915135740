import React, { useState } from "react";
//Material Ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

export const AdminCommonTable = ({ Data, handleClickOpen }) => {
  const [visible, setVisible] = useState(10);
  const handleAdd = () => {
    setVisible((previousValue) => previousValue + 10);
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sr No</TableCell>
            <TableCell>Context</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Data.length === 0 ? (
            <TableRow>
              <TableCell>No Data Found</TableCell>
            </TableRow>
          ) : (
            Data.slice(0, visible).map((item, index) => {
              const { state, documentID } = item;
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{state}</TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickOpen(documentID)}
                  >
                    Edit
                  </TableCell>
                </TableRow>
              );
            })
          )}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <p onClick={handleAdd} style={{ cursor: "pointer" }}>
                Next 10
              </p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
