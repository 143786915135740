import React from "react";
import { Box, makeStyles, Grid } from "@material-ui/core";
//Aos Effect
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
//Router

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "5% auto",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  itemGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // margin: "0 auto",
    padding: "4%",
  },
  subHeader: {
    marginTop:"30px",
    font: "normal normal normal 1.5vw Montserrat",
    cursor: "pointer",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      marginTop: "2%",
    },
  },
  imgWrapper: {
    overflow: "hidden",
    borderRadius: "10px",
    height: "350px",
    transition: "all 500ms ease",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    "&:hover": {
      transition: "all 500ms ease",
      "& img": {
        transform: "scale(1.1)",
      },
    },
  },
  hover: {
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover",
    cursor: "pointer",
    transition: "all 500ms ease",
    transform: "scale(1.01)",
    borderRadius:"30px"
  },
  link: {
    marginTop: "0.5rem",
    transition: "all .3s ease",
    color: "#168ACB",
    cursor: "pointer",
    "&:hover": { opacity: "0.8" },
  },
  role: {
    fontSize: "1rem",
    fontWeight: "400",
    marginTop: "0.5rem",
    color: "black",
    textAlign: "center",
  },
  Headering: {
    font: "normal normal bold 1.2vw Montserrat",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  '@media (max-width: 1367px)': {
    root: {
      margin: "2% auto",
    },
  },
}));

export const TeamCard = ({ data }) => {
  const classes = useStyles();
  const history = useNavigate();

  return (
    <Box className={classes.root}  data-aos="fade-up">
      <Grid className={classes.gridWrapper} container xs={12}>
        {data ? (
          data.map((item, index) => {
            const { profileImage, memberName, id } = item;
            return (
              <Grid
              data-aos="fade-up"
                key={index}
                item
                xs={12}
                sm={6}
                className={classes.itemGrid}
              >
                <Box className={classes.imgWrapper}>
                  <img
                    className={classes.hover}
                    src={profileImage}
                    alt="model"
                    onClick={() => history(`/aboutTeam/${id}`)}
                  />
                </Box>
                <br />
                <h1 className={classes.subHeader}>{memberName}</h1>
                <h2 className={classes.role}>Partner</h2>
                <h3
                  className={classes.link}
                  onClick={() => history(`/aboutTeam/${id}`)}
                >
                  Read more
                </h3>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} sm={4} className={classes.itemGrid}>
            <h1>Loading</h1>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
