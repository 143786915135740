import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import "aos/dist/aos.css";
import aos from "aos";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "2rem",
      marginBottom: "-2rem",
    },
  },
  position: {
    width: "50%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0%",
      width: "100%",
    },
  },
  opa: {
    marginTop: "2%",
    marginLeft: "5%",
    textAlign: "left",
    color: "#168ACB",
    opacity: "0.1",
    font: "normal normal bold Montserrat",
    fontSize: "128px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6rem",
    },
  },
  Head: {
    color: "#168ACB",
    textAlign: "left",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    position: "absolute",
    top: "22%",
    left: "8%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      top: "5%",
    },
  },
  marLeft: {
    marginLeft: "8%",
  },
  praCenter: {
    textAlign: "left",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "1.4",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
      marginBottom: "10%",
      fontWeight: "600",
      marginLeft: "-.5rem",
    },
  },
  Image: {
    width: "100%",
    marginTop: "12%",
    paddingRight:"10%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginLeft: "2%",
    },
  },
  ul: {
    margin: "6% 0% 0% 5%",
    textAlign: "left",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  li: {
    marginTop: "5%",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: "10% 0%",
    },
  },
}));

export const RightFlex = ({ data }) => {
  const classes = useStyles();
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.position}>
        <img
          className={classes.Image}
          src={"./Images/Plateform/platform1.png"}
          alt="Svg"
          data-aos="fade-up"
        />
      </Box>

      <Box className={classes.position}>
        <div style={{
          marginTop:"15%",
        }}>
          <h1 className={classes.opa}>01</h1>
          <h1 className={classes.Head}>{data ? data[35].state : "loading"}</h1>
          <Box className={classes.marLeft}>
            <p className={classes.praCenter} data-aos="fade-up">
              {data ? data[0].state : "loading"}
            </p>

            <ul className={classes.ul}>
              <li className={classes.li} data-aos="fade-up">
                {data ? data[14].state : "loading"}
              </li>
              <li className={classes.li} data-aos="fade-up">
                {data ? data[23].state : "loading"}
              </li>
              <li className={classes.li} data-aos="fade-up">
                {data ? data[20].state : "loading"}
              </li>
            </ul>
          </Box>
        </div>
      </Box>
    </Box>
  );
};
