import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  // mainBox: {
  //   width: "80%",
  //   margin: "13% auto",
  //   [theme.breakpoints.down("sm")]: {
  //     flexDirection: "column",
  //   },
  // },
  leftBox: {
    width: "100%",
    "& img": {
      width: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightBox: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  HeadCenter: {
    textAlign: "center",
    font: "normal normal bold 3rem Montserrat",
    color: "#33333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      marginTop: "5%",
    },
  },
  praCenter: {
    width: "65%",
    margin: "0 auto",
    font: "normal normal normal 2vw Montserrat",
    lineHeight: "1.4",
    textAlign: "center",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: "10% 0",
      textAlign: "justify",
    },
  },
  analytics: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h1": {
      fontWeight: "bolder",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  boxes: {
    width: "28%",
    height: "20vw",
    // padding: '8% 1%',
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    // justifyContent: 'space-around',
    alignItems: "center",
    boxShadow: "0px 9px 50px #00000017",
    "& h2": {
      font: "normal normal bolder 1.5rem Montserrat",
      color: "#33333",
      // marginTop: '20%',
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem",
      },
    },
    "& p": {
      font: "normal normal normal 1.2rem Montserrat",
      marginTop: "10%",
      color: "#33333",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4vw",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
      height: "20vh",
      padding: "auto .5rem",
    },
  },
  imageBox: {
    width: "4vw",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "8vw",
    },
  },
  backImg: {
    width: "100%",
    height: "100%",
    backgroundPosition: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20px 0",
      fontSize: "1rem",
      lineHeight: "1.5",
      height: "300px",
    },
  },
  overlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 70px)",
    height: "calc(100% - 80px)",
    backgroundColor: "#FFFDFD",
    borderRadius: "20px",
    opacity: 0.9,
    display: "flex",
    padding: "5%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      top: "58%",
      flexDirection: "column",
    },
  },
  PositionRelative: {
    position: "relative",
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    textAlign: "center",
    margin: "-2% 10% 3% 10%",
  },
  mainHeader_para: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
    textAlign: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    background: "#2D2D2D",
    height: "70vh",
    borderRadius: "20px",
    margin: "0 25px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "-80px",
    },
  },
  headerText: {
    fontSize: "32px",
    fontFamily: "PlayfairDisplay-Regular",
    fontWeight: "700",
    textAlign: "center",
    marginTop: "80px",
    marginBottom: "120px",
    color: "#171616",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "120px",
    },
  },
  modelText: {
    fontSize: "32px",
    fontFamily: "PlayfairDisplay-Regular",
    fontWeight: "700",
    textAlign: "left",
    marginTop: "80px",
    marginBottom: "120px",
    padding: "25px",
    color: "#171616",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "120px",
    },
  },
  contentText: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "500",
    textAlign: "left",
    color: "#FFFDFD",
    marginTop: "30px",
    padding: "0px 10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
    },
  },
  textContainer: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20px",
    },
  },
  imageContainer: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    borderRadius: "20px",
  },
  mainBox: {
    width: "95%",
    margin: "10% auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "-15%",
    },
  },
  leftBox: {
    width: "100%",
    "& img": {
      width: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightBox: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  HeadCenter: {
    textAlign: "center",
    font: "normal normal bold 3rem Montserrat",
    color: "#33333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      marginTop: "5%",
    },
  },
  praCenter: {
    width: "65%",
    margin: "0 auto",
    font: "normal normal normal 2vw Montserrat",
    lineHeight: "1.4",
    textAlign: "center",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: "10% 0",
      textAlign: "justify",
    },
  },
  analytics: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h1": {
      fontWeight: "bolder",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  boxes: {
    width: "28%",
    height: "20vw",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
      height: "20vh",
      padding: "auto .5rem",
    },
  },
  imageBox: {
    width: "4vw",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "8vw",
    },
  },
  model_image: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      background:
        "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
      borderRadius: "10px",
    },
  },
  model_text: {
    marginTop: "0px",
    fontSize: "20px",
    fontFamily: "Nunito-Regular",
    padding: "12px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      textAlign: "center",
      marginTop: "-5px",
    },
  },
  image_container: {
    background: "rgba(255, 255, 255, 0.8)",
    width: "100%",
    height: "auto",
    textAlign: "center",
    borderRadius: "24px",
    boxShadow: "0px 1px 4px 0px #00000040",
  },
  grid_container: {
    width: "100%",
    padding: "10px 40px",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  image_container_header: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "700",
    textAlign: "center",
    padding: "10px",
    marginTop: "10px",
  },
  image_container_para: {
    fontSize: "20px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
    textAlign: "center",
    padding: "10px",
    marginTop: "10px",
  },
  "@media (max-width: 1921px)": {
    image_container: {
      minHeight: "485px",
    },
  },
}));

export const Offer = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box className={classes.PositionRelative} data-aos="fade-right">
      <img src={"/Images/Bg/BG19.png"} className={classes.backImg} />
      <div className={classes.overlay}>
        <Box
          style={{
            width: "100%",
          }}
        >
          <p className={classes.mainHeader}>
            <span className={`position-relative`}>
              Our Institutional Stewardship
            </span>
            <div className="highlighter"></div>
          </p>
          <p className={classes.mainHeader_para}>
            {data ? data[39].state : "loading"}.{" "}
            {data ? data[29].state : "loading"}
          </p>
          <Box className={classes.mainBox} data-aos="fade-up">
            <Box className={classes.analytics}>
              <Box className={classes.boxes} >
                <div className={classes.image_container}>
                  <img
                    className={classes.img}
                    src="/Images/Plateform/platform8.png"
                    alt="saving"
                  />
                  <div>
                    <p className={classes.image_container_header}>
                      {data ? data[42].state : "loading"}
                    </p>
                    <p className={classes.image_container_para}>
                      {data ? data[11].state : "loading"}
                    </p>
                  </div>
                </div>
              </Box>
              <h1 className={classes.small}>
                +
              </h1>
              <Box className={classes.boxes} data-aos="fade-left">
                <div className={classes.image_container}>
                  <img
                    className={classes.img}
                    src="/Images/Plateform/platform9.png"
                    alt="saving"
                  />
                  <div>
                    <p className={classes.image_container_header}>
                      {data ? data[7].state : "loading"}
                    </p>
                    <p className={classes.image_container_para}>
                      {data ? data[19].state : "loading"}
                    </p>
                  </div>
                </div>
              </Box>

              <h1 className={classes.small} >
                =
              </h1>
              <Box className={classes.boxes} data-aos="fade-right">
                <div className={classes.image_container}>
                  <img
                    className={classes.img}
                    src="/Images/Plateform/platform9.png"
                    alt="saving"
                  />
                  <div>
                    <p className={classes.image_container_header}>
                      {data ? data[4].state : "loading"}{" "}
                      {data ? data[10].state : "loading"}
                    </p>
                    <p className={classes.image_container_para}>
                      {data ? data[43].state : "loading"}
                    </p>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
