import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { LeftFlex } from "./LeftFlex/LeftFlex";
import { LeftFlex1 } from "./LeftFlex/LeftFlex1";
import { RightFlex } from "./RightFlex/RightFlex";
import { RightFlex1 } from "./RightFlex/RightFlex1";
import "aos/dist/aos.css";
import aos from "aos";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5%",
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30%",
    },
  },
  HeadCenter: {
    font: "normal normal bold 1.8vw Montserrat",
    color: "#168ACB",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  praCenter: {
    font: "normal normal normal 2rem Montserrat",
    textAlign: "center",
    marginTop: "2%",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1.2rem",
    },
  },
  Wraper: {
    width: "95%",
    margin: "0% auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  Img: {
    width: "50%",
    margin: "0% auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    textAlign: "center",
  },
}));

export const OurProcess = ({ data }) => {
  const classes = useStyles();
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);
  return (
    <Box className={classes.root}>
      <p className={classes.mainHeader}>
        <span data-aos="fade-up" className={`position-relative`}>
          Our Methods
        </span>
        <div className="highlighter"></div>
      </p>
      <Box className={classes.Wraper}>
        <RightFlex data={data} />
        <Box className={classes.Img}>
          <img
            style={{ width: "100%" }}
            src={"./Images/Line/Path 3711.svg"}
            alt="line"
            data-aos="fade-up"
          />
        </Box>

        <LeftFlex data={data} />
        <Box className={classes.Img}>
          <img
            style={{ width: "100%" }}
            src={"./Images/Line/Path 1516.svg"}
            alt="line"
            data-aos="fade-up"
          />
        </Box>
        <RightFlex1 data={data} />
        <Box className={classes.Img}>
          <img
            style={{ width: "100%" }}
            src={"./Images/Line/Path 3711.svg"}
            alt="line"
            data-aos="fade-up"
          />
        </Box>
        <LeftFlex1 data={data} />
      </Box>
    </Box>
  );
};
