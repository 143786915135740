import React, { Fragment, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import emailjs from "emailjs-com";
import aos from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "react-scroll-to-top";

const useStyles = makeStyles((theme) => ({
  PositionRelative: {
    display: "flex",
    position: "relative",
    marginTop: "150px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rightContent: {
    flex: 1,
    padding: "170px 80px",
    marginLeft: "50px",
  },
  leftContent: {
    flex: 1,
    padding: "10px",
    marginLeft: "30px",
    borderRadius: "100px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  leftContentMap: {
    width: "100%",
    borderRadius: "12px",
    [theme.breakpoints.up("sm")]: {
      height: "400px",
    },
  },
  rightContentText: {
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    marginBottom: "100px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

export const AddressLocation = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Fragment>
      <ScrollToTop />
      <Box className={classes.PositionRelative} data-aos="fade-up">
        <div className={classes.leftContent}>
          <iframe
            src={data ? data[0].mapLink : ""}
            width="100%"
            height="640px"
            style={{ borderRadius: "12px", borderColor: "transparent" }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className={classes.rightContent} data-aos="fade-right">
          <div className={classes.rightContentText}>
            <p>Address</p>
            <p>{data ? data[0].address : "loding"}</p>
          </div>
          <div className={classes.rightContentText}>
            <p>Email </p>
            <p>{data ? data[0].email : "loding"}</p>
          </div>
        </div>
      </Box>
    </Fragment>
  );
};
