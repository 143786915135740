import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  title: {
    fontFamily: "Montserrat-semibold",
    padding: "10px 20px",
  },
  pageLink: {
    padding: "1.5rem 1rem",
    display: "grid",
    placeContent: "center",
    borderRadius: "6px",
    backgroundColor: "#168acb",
    border: "none",
    color: "#ffffff",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    cursor: "pointer",
    marginTop: "30px",
  },
  wrapper: {
    minHeight: "600px",
    padding: "10px 20px",
    display: "grid",
    placeContent: "center",
  },
});
export default function Error404() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>Page Not Found</h1>
      <Link to="/" className={classes.pageLink}>
        Return to Homepage
      </Link>
    </div>
  );
}
