import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { firestore, storage } from "../../../../Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "2% auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputDiv: {
    width: "45%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    // alignItems: 'center',
    flexDirection: "column",
  },
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
  },
  inputFile: {
    width: "80%",
    border: "2px solid black",
    borderRadius: "10px",
  },
  button: {
    width: "35%",
    border: "2px solid black",
    borderRadius: "10px",
    padding: "1%",
    fontSize: "15px",
  },
  VideoBox: {
    width: "45%",
    margin: "2% auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  video: {
    width: "100%",
    height: "100%",
    border: "1px solid black",
  },
}));

export const Video = () => {
  const classes = useStyles();

  const [video, setVideo] = useState([]);

  const [fetch, setFetch] = useState([]);

  const [videoId, setVideoId] = useState("");

  const handleChangeFile = (event) => {
    setVideo(event.target.files[0]);
  };

  const handleSubmitVideo = (event) => {
    event.preventDefault();

    if (video.length === 0) {
      toast.error("Please fill all the required fields!");
    } else {
      const uploadTask = storage.ref(`video/${video.name}`).put(video);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressStatus = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          progressStatus === 0
            ? toast.info("pending")
            : toast.info("Please wait...");
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("video")
            .child(video.name)
            .getDownloadURL()
            .then((videoUrl) => {
              firestore
                .collection("video")
                .doc(videoId ? videoId : "")
                .update({ video: videoUrl })
                .then(() => {
                  toast.success("video Added");
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(err);
                });
            });
        }
      );
    }
  };

  const handleFetch = () => {
    firestore.collection("video").onSnapshot((querySnapshot) => {
      const item = [];
      querySnapshot.forEach((doc) => {
        item.push(doc.data());
        setVideoId(doc.id);
      });
      setFetch(item);
    });
  };

  useEffect(() => {
    handleFetch();
  }, [video]);

  return (
    <div className={classes.root}>
      <div className={classes.inputDiv}>
        <h3 className={classes.Heading}>Video Only</h3>
        <br />
        <input
          className={classes.inputFile}
          type="file"
          placeholder="logo"
          accept="video/mp4,video/x-m4v,video/*"
          onChange={handleChangeFile}
        />
        <br />
        <button className={classes.button} onClick={handleSubmitVideo}>
          Submit
        </button>
        {fetch
          ? fetch.map((item, index) => {
              return <source src={item.video} key={index} />;
            })
          : ""}
      </div>
      <div className={classes.VideoBox}>
        <video controls autoPlay muted loop className={classes.video}>
          {fetch
            ? fetch.map((item, index) => {
                return <source src={item.video} key={index} />;
              })
            : ""}
        </video>
      </div>
    </div>
  );
};

// className={classes.video} poster={poster} data-aos="fade-up" data-aos-delay="200"
