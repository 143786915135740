import React, { Fragment, useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  overlay_text: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    color: "#171616",
  },
  overlay_sub_para: {
    fontFamily: "Roboto-Regular",
    fontSize: "32px",
    fontWeight: "700",
    color: "#171616",
  },
  overlay_sub_text: {
    fontFamily: "Nunito-Regular",
    fontSize: "24px",
    fontWeight: "500",
    color: "#2D2D2D",
    textAlign: "justify",
    marginTop: "30px",
    marginBottom: "20px",
  },
  Collaborator_banner: {
    height: "187px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#ffff",
    opacity: "1",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "80px",
      marginTop: "10px",
    },
  },
  Collaborator_banner_logo: {
    width: "100%",
    height: "100%",
  },
  sub_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  arrow: {
    margin: "0 16px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
  },
  div: {
    flex: 1,
    textAlign: "center",
    width: "100%",
    height: "100%",
  },
  div_image: {
    width: "100%",
    height: "auto",
    backgroundImage:
      "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
    padding: "2px",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  img_text: {
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "36px",
    fontWeight: "600",
    color: "#171616",
  },

  main: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "150px",
    },
  },
  backImg: {
    width: "100%",
    height: "100vh",
    "& img": {
      maxHeight: "100%",
      width: "100%",
    },
  },
  BoxPosition: {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.down("sm")]: {
    },
  },
  HeadCenter: {
    font: "normal normal bold 3.5rem Montserrat",
    color: "#ffffff",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  praCenter: {
    width: "50%",
    font: "normal normal normal 1.5rem Montserrat",
    color: "#ffffff",
    lineHeight: "1.4",
    textAlign: "center",
    margin: "2% auto",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      fontSize: ".8rem",
      margin: "0% auto",
    },
  },
  backImg: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20px 0",
      fontSize: "1rem",
      lineHeight: "1.5",
      height: "300px",
    },
  },
  overlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: "3%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 50px)",
    height: "calc(100% - 50px)",
    backgroundColor: "#FFFDFD",
    borderRadius: "20px",
    opacity: 0.9,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      top: "58%",
      flexDirection: "column",
    },
  },
  PositionRelative: {
    position: "relative",
    marginTop:"-200px"
  },
  leftContent: {
    flex: 1,
    padding: "20px",
    marginTop: "100px",
  },
  rightContent: {
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "10px",
    textAlign: "center",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  leftContentText: {
    color: "#168ACB",
    fontSize: "85px",
    fontFamily: "Nunito-Regular",
    fontWeight: "600",
    marginTop: "-30px",
    marginLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  leftContentText_header: {
    fontSize: "36px",
    fontFamily: "Nunito-Regular",
    fontWeight: "700",
    marginTop: "130px",
    marginLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  rightContentImg: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "100%",
      marginTop: "-10px",
    },
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    textAlign: "center",
    margin: "-2% 10% 5% 10%",
  },
  mainHeader_para: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
    textAlign: "center",
  },
}));

export const TeamCard = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box className={classes.PositionRelative} data-aos="fade-up">
      <img src={"/Images/Bg/BG11.png"} className={classes.backImg} />
      <div className={classes.overlay}>
        <Box style={{ textAlign: "center" }}>
          <p className={classes.overlay_text}>
            <span className="position-relative">
              {data ? data[11].state : "loading"}
            </span>
            <div className="highlighter"></div>
          </p>
          <p className={classes.overlay_sub_text}>
            At Quain Investments, we are using a proven model, have
            institutionalized it with our QuainBrain™ technology platform and we
            provide institutional stewardship to implement TechVenture@Scale™
          </p>
          <p className={classes.overlay_sub_text}>
            {data ? data[2].state : "loading"}
          </p>
          <p className={classes.overlay_sub_para} data-aos="fade-up">
            “Crafting Tomorrow’s Success Today”
          </p>
          <div className={classes.sub_container} data-aos="fade-up">
            <div className={classes.div} >
              <img
                className={classes.div_image}
                src="/Images/About/access.jpg"
                alt="access"
              />
              <p className={classes.img_text}>Access</p>
            </div>
            <div className={classes.arrow}>
              <img src="/Images/About/arrows.png" alt="arrow" />
            </div>
            <div className={classes.div} data-aos="fade-up">
              <img
                className={classes.div_image}
                src="/Images/About/process.jpg"
                alt="access"
              />
              <p className={classes.img_text}>Process</p>
            </div>
            <div className={classes.arrow}>
              <img src="/Images/About/arrows.png" alt="arrow" />
            </div>
            <div className={classes.div} data-aos="fade-up">
              <img
                className={classes.div_image}
                src="/Images/About/decide.jpg"
                alt="access"
              />
              <p className={classes.img_text}>Decide</p>
            </div>
          </div>
        </Box>
      </div>
    </Box>
  );
};
