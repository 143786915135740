import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Intro } from "./Intro";
import { OurProcess } from "./OurProcess";
import { Card } from "./Card";
import { WhyQuain } from "./WhyQuain";
import { Offer } from "./Offer";
import { ScrollTop } from "../../Molecule/ScrollToTop";
import { firestore } from "../../../Firebase";
import { AboutSection } from "./AboutSection";

export const AboutPlatform = () => {
  const [fetch, setFetch] = useState("");

  const handleFetch = () => {
    firestore.collection("platformPageContent").onSnapshot((querySnapshot) => {
      const itemdummy = [];
      itemdummy.length = [querySnapshot.docs.length];
      querySnapshot.forEach((doc) => {
        if (doc.id === "11M9EcFv1SzxpqRo3Di3")
          itemdummy[0] = doc.data();
        else if (doc.id === "1opFLNgaf5H1YCBX7uZR")
          itemdummy[1] = doc.data();
        else if (doc.id === "2QoMjqM77aS45aPTzd84")
          itemdummy[2] = doc.data();
        else if (doc.id === "42xfoQhP1uixtL4RnFce")
          itemdummy[3] = doc.data();
        else if (doc.id === "4TN4GFo9DRXCeaDWW2qK")
          itemdummy[4] = doc.data();
        else if (doc.id === "5JcKoHHVwtxF80TSGjUH")
          itemdummy[5] = doc.data();


        else if (doc.id === "5n9Fp9z1ZcCUOGwfxfZr")
          itemdummy[6] = doc.data();
        else if (doc.id === "7LT05HG3zXKxkBJ4Kfq8")
          itemdummy[7] = doc.data();
        else if (doc.id === "80lL2OameYi503haAL9B")
          itemdummy[8] = doc.data();
        else if (doc.id === "8xgcvoyE2DkFUqvOWnrH")
          itemdummy[9] = doc.data();
        else if (doc.id === "CSVHbgpl8QdJxzsxrAkG")
          itemdummy[10] = doc.data();
        else if (doc.id === "D8ARf1WqFxqEVJtrULdN")
          itemdummy[11] = doc.data();
        else if (doc.id === "F2FoGnWhrlqibsk8aTsH")
          itemdummy[12] = doc.data();



        else if (doc.id === "FLLQ5oORPB6eIIEn3EdA")
          itemdummy[13] = doc.data();
        else if (doc.id === "IoAelblJYsUpUabOd1S7")
          itemdummy[14] = doc.data();
        else if (doc.id === "JH8Mdu2JpYakp27Z7p1p")
          itemdummy[15] = doc.data();

        else if (doc.id === "JsMjC7YBfzFzA48lLq2v")
          itemdummy[16] = doc.data();
        else if (doc.id === "Koa5D6qV0nAcDKUtJzdX")
          itemdummy[17] = doc.data();
        else if (doc.id === "LlcpkgBsR3GV0zO8N6hQ")
          itemdummy[18] = doc.data();
        else if (doc.id === "PBBzj83i9bujlqyBudKY")
          itemdummy[19] = doc.data();
        else if (doc.id === "Q9liowfg3TqQp8HpcfT8")
          itemdummy[20] = doc.data();



        else if (doc.id === "WLzy6n4GgaGezdtR0w8F")
          itemdummy[21] = doc.data();
        else if (doc.id === "XofytiSCEGM4jvnOvJ6S")
          itemdummy[22] = doc.data();
        else if (doc.id === "ax1cL8RbwWGs4Vgh5eyd")
          itemdummy[23] = doc.data();
        else if (doc.id === "b1nGnwTh0SqAx56XflJI")
          itemdummy[24] = doc.data();


        else if (doc.id === "bWInzBhdMxY2CcXQnl6z")
          itemdummy[25] = doc.data();
        else if (doc.id === "baM4D8FfRzORa4qUNojd")
          itemdummy[26] = doc.data();
        else if (doc.id === "d48TRWxToHjao0YVXpYQ")
          itemdummy[27] = doc.data();
        else if (doc.id === "faImcugIRJTM5ksUanUQ")
          itemdummy[28] = doc.data();
        else if (doc.id === "gnuQWihQX47ZG5m3X6EY")
          itemdummy[29] = doc.data();


        else if (doc.id === "hAT48J0mGTFObRAX1q9U")
          itemdummy[30] = doc.data();
        else if (doc.id === "ig4kl51bmki8UM2we3uy")
          itemdummy[31] = doc.data();
        else if (doc.id === "jbBGX8aFGE7mECaCbsGd")
          itemdummy[32] = doc.data();
        else if (doc.id === "k37Cw59OFPDMlA15CJBW")
          itemdummy[33] = doc.data();
        else if (doc.id === "kE6Zb5MshpcvHxulHO9A")
          itemdummy[34] = doc.data();


        else if (doc.id === "m7VDe1G02BMCCkMwDuhx")
          itemdummy[35] = doc.data();
        else if (doc.id === "nssiRiEVICFlEhP2aOUY")
          itemdummy[36] = doc.data();
        else if (doc.id === "qnmTLpP0FLkX9DtqYbKX")
          itemdummy[37] = doc.data();
        else if (doc.id === "sxjI72w8lFHMXugOoA6s")
          itemdummy[38] = doc.data();
        else if (doc.id === "vOORPAgRJpm9cjy4YnWI")
          itemdummy[39] = doc.data();

        else if (doc.id === "ytdvror20kVtFgcnRzmQ")
          itemdummy[40] = doc.data();
        else if (doc.id === "zPTv9XGCMSPEIdSbRAll")
          itemdummy[41] = doc.data();
        else if (doc.id === "ziBmBtcE4w21UQ3PSAKC")
          itemdummy[42] = doc.data();
        else if (doc.id === "8PAYVKvQFKblT6I2cOmN")
          itemdummy[43] = doc.data();
      });

      setFetch(itemdummy);
    });
  };

  useEffect(() => {
    window.scroll({ top: 1, left: 0, behavior: "smooth" });
    handleFetch();
  }, []);
  return (
    <Box>
      <ScrollTop />
      <Intro data={fetch} />
      <AboutSection data={fetch} />
      <OurProcess data={fetch} />
      <WhyQuain data={fetch} />
      <Offer data={fetch} />
    </Box>
  );
};


