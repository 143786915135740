import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  overlay: {
    width: "calc(100% - 70px)",
    height: "calc(100% - 150px)",
    backgroundColor: "#FFFDFD",
    borderRadius: "20px",
    opacity: 0.9,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      top: "58%",
      flexDirection: "column",
    },
  },
  leftContent: {
    flex: 1,
    padding: "20px",
  },
  rightContent: {
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  leftContentText: {
    fontSize: "32px",
    fontFamily: "Nunito-Regular",
    fontWeight: "600",
    textAlign: "left",
    marginTop: "50px",
    marginLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  leftContentText_header: {
    fontSize: "36px",
    fontFamily: "Nunito-Regular",
    fontWeight: "700",
    marginTop: "50px",
    marginLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "20px",
    },
  },
  rightContentImg: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      height: "100%",
      marginTop: "-10px",
    },
  },
  mainHeader: {
    display: "inline-block",
    fontFamily: "PlayfairDisplay-Regular",
    fontSize: "32px",
    fontWeight: "700",
    margin: "50px 10px",
  },
}));

export const AboutSection = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box style={{ textAlign: "center", width: "100%" }}>
      <p className={classes.mainHeader}>
        <span className={`position-relative`}>
          {data ? data[5].state : "loading"}
        </span>
        <div className="highlighter"></div>
      </p>
      <div className={classes.overlay}>
        <div className={classes.leftContent} data-aos="fade-up">
          <p className={classes.leftContentText}>
            {data ? data[7].state : "loading"}
          </p>
        </div>
        <div className={classes.rightContent} data-aos="fade-right">
          <img
            className={classes.rightContentImg}
            src="./Images/About/about-section.png"
            alt=""
          />
        </div>
      </div>
    </Box>
  );
};
