import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  inputField: {
    padding: "4%",
    width: "12rem",
    borderRadius: "10px",
    border: "1px solid #000",
  },
  inputFieldFiles: {
    padding: "2%",
    width: "12rem",
    borderRadius: "10px",
    display: "inline-block",
    content: "Select some files",
    border: "1px solid #000",
  },
  button: {
    width: "12rem",
    padding: "4%",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#168ACB",
    color: "#fff",
    font: "normal normal bold 1rem Montserrat",
    cursor: "pointer",
    marginRight: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginBottom: "5%",
      width: "5rem",
    },
  },
}));

export const UpdateTilesPopup = ({
  open,
  handleClose,
  handleUpdateTiles,
  tileDetails,
}) => {
  const classes = useStyles();

  const [portfolio, setPortfolio] = useState({
    companyName: "",
    sector: "",
    stage: "",
    link: "",
  });
  const [count, setCount] = useState(1);
  const [logo, setLogo] = useState([]);

  const { companyName, sector, stage, link } = portfolio;
  useEffect(() => {

    if (tileDetails) {
      let portfolios = portfolio;
      portfolios.companyName = tileDetails.companyName;
      portfolios.sector = tileDetails.sector;
      portfolios.stage = tileDetails.stage;
      portfolios.link = tileDetails.link;
      setPortfolio(portfolios)
      setCount(count + 1)
    }
  }, [tileDetails])
  const handleChangeFile = (event) => {
    setLogo(event.target.files[0]);
  };

  let name, value;

  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    setPortfolio({ ...portfolio, [name]: value });
  };

  const state = { companyName, sector, stage, logo, link };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!companyName || !sector || !stage || !logo || !link) {
      toast.error("Please fill the input field");
    } else {
      handleUpdateTiles(state);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ textAlign: "center" }}>Update Tiles</DialogTitle>
      <DialogContent>
        <form onSubmit={handleUpdate}>
          <input
            className={classes.inputField}
            placeholder={tileDetails.companyName}
            name="companyName"
            value={portfolio.companyName}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            className={classes.inputField}
            placeholder={tileDetails.sector}
            name="sector"
            value={portfolio.sector}
            onChange={handleChange}
          />
          <br />
          <br />
          <select
            className={classes.inputField}
            name="stage"
            value={portfolio.stage}
            onChange={handleChange}
          >
            <option>Selected</option>
            <option value="Early">Early</option>
            <option value="Growth">Growth</option>
          </select>
          <br />
          <br />
          <input
            className={classes.inputField}
            placeholder={tileDetails.link ? tileDetails.link : "Link"}
            name="link"
            value={portfolio.link}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            className={classes.inputFieldFiles}
            type="file"
            placeholder="logo"
            onChange={handleChangeFile}
          />
          <br />
          <br />
          <button className={classes.button} type="submit">
            Update
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
