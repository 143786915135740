import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { auth } from "../../../../Firebase";
import {signInWithEmailAndPassword } from "firebase/auth";

import {
  Container,
  Box,
  Link,
  TextField,
  Typography,
  CssBaseline,
  Button,
  Avatar,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth, getIdToken } from "firebase/auth";

function Copyright() {
  return (
    <div>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <Link color="inherit" href="/">
          Go to Home
        </Link>
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  loader: {
    display: "grid",
    placeContent: "center",
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    height: "100%",
    width: "100%",
    zIndex: "100",
    background: "rgba(0,0,0,0.8)",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function Login() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const auth = getAuth();

  const Loader = () => {
    return (
      <Box className={classes.loader}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            margin: "auto",
            background: "none",
            display: "block",
            shapeRendering: "auto",
          }}
          width="100px"
          height="100px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx={50}
            cy={50}
            fill="none"
            stroke="#168acb"
            strokeWidth={10}
            r={35}
            strokeDasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            />
          </circle>
        </svg>
      </Box>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    document.body.style.overflowY = "hidden";
    console.log({ username, password });
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      const user = userCredential.user;
      toast.success("Signin Successful.");
  
      const token = await getIdToken(user);
      sessionStorage.setItem("authToken", token);
      history("/admin");
    } catch (err) {
      console.error(err);
      toast.error("Invalid Authentication Details.");
    } finally {
      setLoading(false);
      document.body.style.overflowY = "auto";
    }
  };
  
  // Function to get ID token
  const getIdToken = async (user) => {
    const idTokenResult = await user.getIdTokenResult();
    return idTokenResult.token;
  };

  return (
    <div style={{ marginTop: "10%" }}>
      {isLoading ? <Loader /> : ""}
      <Container component="main" maxWidth="xs">
        <ToastContainer />
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="User Name"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
