import React, { Fragment, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
//Aos Effect
import aos from "aos";
import "aos/dist/aos.css";
//BG
import BG from "../../../Assets/BG4.png";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    background: "#2D2D2D",
    height: "70vh",
    borderRadius: "20px",
    margin: "0 25px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "-80px",
    },
  },
  headerText: {
    fontSize: "32px",
    fontFamily: "PlayfairDisplay-Regular",
    fontWeight: "700",
    textAlign: "center",
    marginTop: "80px",
    marginBottom: "120px",
    color: "#171616",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "120px",
    },
  },
  modelText: {
    fontSize: "32px",
    fontFamily: "PlayfairDisplay-Regular",
    fontWeight: "700",
    textAlign: "left",
    marginTop: "80px",
    marginBottom: "120px",
    padding: "25px",
    color: "#171616",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "120px",
    },
  },
  contentText: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "500",
    textAlign: "left",
    color: "#FFFDFD",
    marginTop: "30px",
    padding: "0px 10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
    },
  },
  textContainer: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20px",
    },
  },
  mainBox: {
    width: "95%",
    margin: "15% auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "-15%",
    },
  },
  leftBox: {
    width: "100%",
    "& img": {
      width: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightBox: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  HeadCenter: {
    textAlign: "center",
    font: "normal normal bold 3rem Montserrat",
    color: "#33333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      marginTop: "5%",
    },
  },
  praCenter: {
    width: "65%",
    margin: "0 auto",
    font: "normal normal normal 2vw Montserrat",
    lineHeight: "1.4",
    textAlign: "center",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: "10% 0",
      textAlign: "justify",
    },
  },
  analytics: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h1": {
      opacity: ".3",
      fontWeight: "bolder",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  boxes: {
    width: "28%",
    height: "20vw",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
      height: "20vh",
      padding: "auto .5rem",
    },
  },
  imageBox: {
    width: "4vw",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "8vw",
    },
  },
  model_image: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      background:
        "linear-gradient(114deg, #119EED 0%, rgba(2, 202, 142, 0.97) 92.66%)",
      borderRadius: "10px",
    },
  },
  model_text: {
    marginTop: "0px",
    fontSize: "20px",
    fontFamily: "Nunito-Regular",
    padding: "12px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      textAlign: "center",
      marginTop: "-5px",
    },
  },
  image_container: {
    background: "rgba(255, 255, 255, 0.8)",
    width: "100%",
    height: "auto",
    textAlign: "center",
    borderRadius: "24px",
    boxShadow: "0px 1px 4px 0px #00000040",
  },
  grid_container: {
    width: "100%",
    padding: "10px 40px",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  image_container_header: {
    fontSize: "24px",
    fontFamily: "Nunito-Regular",
    fontWeight: "700",
    textAlign: "left",
    padding: "10px",
  },
  image_container_para: {
    fontSize: "20px",
    fontFamily: "Nunito-Regular",
    fontWeight: "400",
    textAlign: "left",
    padding: "5px 10px 10px 30px",
    marginBottom: "20px",
  },
}));

export const WhyQuain = ({ data }) => {
  const classes = useStyles();

  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Fragment>
      <Box className={classes.mainBox}>
        <Box className={classes.analytics}>
          <Box className={classes.boxes} data-aos="fade-right">
            <div className={classes.image_container}>
              <img
                className={classes.img}
                src="/Images/Plateform/platform5.jpg"
                alt="saving"
              />
              <div>
                <p className={classes.image_container_header}>
                {data ? data[1].state : "loading"} <br />{" "}  {data ? data[12].state : "loading"}
                </p>
                <p className={classes.image_container_para}>
                  <ul>
                    <li> {data ? data[8].state : "loading"}</li>
                    <li> {data ? data[42].state : "loading"}</li>
                  </ul>
                </p>
              </div>
            </div>
          </Box>
          <Box className={classes.boxes} data-aos="fade-left">
            <div className={classes.image_container}>
              <img
                className={classes.img}
                src="/Images/Plateform/platform6.png"
                alt="saving"
              />
              <div>
                <p className={classes.image_container_header}>
                {data ? data[28].state : "loading"} <br />{" "}  {data ? data[30].state : "loading"}
                </p>
                <p className={classes.image_container_para}>
                  <ul>
                    <li>
                    {data ? data[6].state : "loading"}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </Box>
          <Box
            className={classes.boxes}
            data-aos="fade-right"
            style={{
              position: "relative",
            }}
          >
            <div className={classes.image_container}>
              <img
                className={classes.img}
                src="/Images/Plateform/platform7.jpeg"
                alt="saving"
              />
              <div>
                <p className={classes.image_container_header}>
                {data ? data[26].state : "loading"} <br />{" "}  {data ? data[21].state : "loading"}
                </p>
                <p className={classes.image_container_para}>
                  <ul>
                    <li>{data ? data[25].state : "loading"}</li>
                    <li> {data ? data[31].state : "loading"}</li>
                  </ul>
                </p>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
