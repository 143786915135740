import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { firestore } from "../../../../Firebase";
import { UpdateHomePop } from "./UpdateTilesPopup";
import { AdminCommonTable } from "../../../Molecule";

const useStyles = makeStyles((theme) => ({
  Heading: {
    font: "normal normal 500 1.8vw Montserrat",
    textAlign: "center",
  },
  portfolioInputBox: {
    border: "2px solid red",
  },
}));

export const PlatformDashboard = () => {
  const classes = useStyles();

  const [state, setState] = useState("");

  const [fetch, setFetch] = useState("");

  const [open, setOpen] = useState(false);

  const [HomeDetails, setHomeDetails] = useState("");

  const [documentID, setDocumentID] = useState("");

  const currentDate = new Date().toISOString();

  const handleClickOpen = (id) => {
    setOpen(true);
    setDocumentID(id);
    firestore
      .collection("platformPageContent")
      .doc(id)
      .get()
      .then((snapshot) => setHomeDetails(snapshot.data()));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (state) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection("platformPageContent")
        .doc(documentID)
        .update({ state })
        .then(() => {
          resolve();
          handleClose();
          toast.success("Updated");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };


  const handleFetch = () => {
    firestore
      .collection("platformPageContent")
      .orderBy("state", "asc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push({ ...doc.data(), documentID: doc.id });
        });
        setFetch(item);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div>
      <h1 className={classes.Heading}>Platform Admin Dashboard</h1>

      <AdminCommonTable Data={fetch} handleClickOpen={handleClickOpen} />
      <UpdateHomePop
        open={open}
        handleClose={handleClose}
        HomeDetails={HomeDetails}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};
